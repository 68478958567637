import { Route, Routes } from "react-router-dom";
import React from "react";
import { TitleSearch } from "@/features/titleSearch/routes/TitleSearch";

export const TitleSearchRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<TitleSearch />} />
    </Routes>
  );
};
