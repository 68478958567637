import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Button, ButtonVariantOption, SubmitButton } from "../Button";

interface Props {
  dialogContentText: string;
  handleClickSubmitAddRowsButton: (cancelLoading: () => void) => void;
  openAddRowsModalFlag: boolean;
  setOpenAddRowsModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * 家屋番号領域のボタン押下時に表示されるダイアログ
 * @param {Props} props
 * @returns {React.FC<Props>}
 */
export const ConfirmDialogKaokuNumber: React.FC<Props> = (props) => {
  const {
    dialogContentText,
    handleClickSubmitAddRowsButton,
    openAddRowsModalFlag,
    setOpenAddRowsModalFlag,
  } = props;

  return (
    <Dialog
      open={openAddRowsModalFlag}
      onClose={() => {
        setOpenAddRowsModalFlag(false);
      }}
    >
      <DialogTitle>{"確認画面"}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContentText}</DialogContentText>
        <DialogContentText>よろしいですか？</DialogContentText>
      </DialogContent>
      <DialogActions>
        <SubmitButton
          variant={ButtonVariantOption.Contained}
          onClick={(_event, cancelLoading) => {
            handleClickSubmitAddRowsButton(cancelLoading);
          }}
          label={"はい"}
          timeout={3000}
        />
        <Button
          variant={ButtonVariantOption.Outlined}
          onClick={() => {
            setOpenAddRowsModalFlag(false);
          }}
          label={"キャンセル"}
        />
      </DialogActions>
    </Dialog>
  );
};
