import {
  Box,
  Button as MuiButton,
  Grid,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Button, ButtonVariantOption } from "../../../components/Button";
import { RowSpacingWrapper, SizingWrapperStyle } from "@/components/Wrapper";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle, SectionTitle } from "@/components/Title";
import { KeyboardArrowLeft } from "@mui/icons-material";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import React from "react";
import { useFeatureFlags } from "@/configs/featureFlag";
import { MultiSelect } from "@/components/Select";
import { Divider } from "@/components/Divider";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Label } from "@/components/Label";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Checkbox, CheckboxGroup } from "@/components/Checkbox";
import { z } from "zod";
import { format, getYear, parse } from "date-fns";
import ja from "date-fns/locale/ja";
import { usePrefectureOptions } from "@/features/realEstateReceptionBookFeed/hooks/usePrefectureOptions";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCityOptions } from "@/features/realEstateReceptionBookFeed/hooks/useCityOptions";
import { useCitySelect } from "@/features/realEstateReceptionBookFeed/hooks/useCitySelect";
import { type ISelectObject } from "@/types/select";
import { useReceptionReasonOptions } from "@/features/realEstateReceptionBookFeed/hooks/useReceptionReasonOptions";
import { getSearchMaxDate } from "@/features/realEstateReceptionBookFeed/utils/dateTime";
import { CMultiSelect } from "@/components/Select/CMultiSelect";
import { DateRangePicker } from "@/components/DatePicker";
import {
  preventNonNumericValueWhenKeyDown,
  preventNonNumericValueWhenPaste,
} from "@/utils/utils";
import { FeedGrid } from "../components/FeedGrid";
import RealEstateDetailModal from "@/features/titleSearch/components/RealEstateDetailModal";

// 築年数で入力可能な最小値を定義する。
const BUILDING_AGE_MIN = 0;
// 築年数で入力可能な最大値を定義する。
// BEのコードで、現在日時のyearからMAX+1を引いた年をstart_dateとしている。
// datetime()のyearに0以下は指定できないので、最大値は現在日時のyear-2とする。
const BUILDING_AGE_MAX =
  getYear(
    parse(
      format(new Date(), "yyyy-MM-dd", { locale: ja }),
      "yyyy-MM-dd",
      new Date()
    )
  ) - 2;
const MIN_ERROR_MESSAGE = `${BUILDING_AGE_MIN}以上の数値を入力してください`;
const MAX_ERROR_MESSAGE = `${BUILDING_AGE_MAX}以下の数値を入力してください`;

/**
 * 最小値最大値を設定する場合の関数
 * @param min 設定可能な最小値
 * @param max 設定可能な最大値
 * @param minErrorMessage 最小値を下回っている場合のエラーメッセージ
 * @param maxErrorMessage 最大値を超えている場合のエラーメッセージ
 * @param rangeErrorMessage 範囲開始値<範囲終了値となっていない場合のエラーメッセージ
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const makeRangeSchemaWithRestrictMinMax = (
  min: number,
  max: number,
  minErrorMessage: string,
  maxErrorMessage: string,
  rangeErrorMessage: string
) => {
  /**
   * 最小値のチェック。
   * @param {string} value
   * @returns {boolean}
   */
  const checkMin = (value: number | null): boolean => {
    if (value === null) return true;
    return min <= value;
  };

  /**
   * 最大値のチェック。
   * @param {string} value
   * @returns {boolean}
   */
  const checkMax = (value: number | null): boolean => {
    if (value === null) return true;
    return max >= value;
  };

  return z
    .object({
      min: z
        .number()
        .nullable()
        .refine((value) => checkMin(value), minErrorMessage)
        .refine((value) => checkMax(value), maxErrorMessage),
      max: z
        .number()
        .nullable()
        .refine((value) => checkMin(value), minErrorMessage)
        .refine((value) => checkMax(value), maxErrorMessage),
    })
    .refine(
      (args) => {
        // min<=maxを確認。
        const { min, max } = args;
        if (min === null || max === null) return true;
        return min <= max;
      },
      {
        message: rangeErrorMessage,
        path: ["min"],
      }
    );
};

/**
 * 最小値最大値を設定しない場合の関数
 * @param rangeErrorMessage 範囲開始値<範囲終了値となっていない場合のエラーメッセージ
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const makeRangeSchema = (rangeErrorMessage: string) => {
  return z
    .object({
      min: z.number().nullable(),
      max: z.number().nullable(),
    })
    .refine(
      (args) => {
        // min<=maxを確認。
        const { min, max } = args;
        if (min === null || max === null) return true;
        return min <= max;
      },
      {
        message: rangeErrorMessage,
      }
    );
};

export const TitleSearch: React.FC = () => {
  const { mypageTop, realEstateRegistrationMonitoring } = useFeatureFlags();

  // 都道府県一覧
  const { prefectureOptions } = usePrefectureOptions();
  // 市区町村一覧
  const { cityParams } = useCityOptions();

  // 築年月の選択可能最大値
  const maxDate = getSearchMaxDate();

  // 登記原因一覧を取得
  const { receptionReasonOptions } = useReceptionReasonOptions();

  const FormDataSchema = z.object({
    prefecture: z.array(z.number()),
    city: z.array(z.number()).nonempty("市区町村を選択してください"),
    areausepurpose: z.array(z.number()),
    yousekiRatio: z.number().nullable(),
    kenpeiRatio: z.number().nullable(),
    realEstateType: z
      .array(z.string())
      .nonempty("不動産種別を最低１つ選択してください"),
    receptionReasons: z.array(z.string()),
    ownerName: z.string(),
    ownerAddress: z.string(),
    landType: z.object({
      farm: z.boolean(),
      factoryStorage: z.boolean(),
      solar: z.boolean(),
    }),
    estimateAcreage: makeRangeSchema(
      "「以上」は「以下」より小さくしてください"
    ),
    price: makeRangeSchema("「以上」は「以下」より小さくしてください"),
    walk: z.number().nullable(),
    buildingType: z.object({
      mansion: z.boolean(),
      apartment: z.boolean(),
      building: z.boolean(),
      jpHotel: z.boolean(),
      shop: z.boolean(),
      hotel: z.boolean(),
      factory: z.boolean(),
    }),
    floorCount: makeRangeSchema("「以上」は「以下」より小さくしてください"),
    buildingVariant: z.number().nullable(),
    buildDateRange: z.tuple([z.date().nullable(), z.date().nullable()]),
    buildingAge: makeRangeSchemaWithRestrictMinMax(
      BUILDING_AGE_MIN,
      BUILDING_AGE_MAX,
      MIN_ERROR_MESSAGE,
      MAX_ERROR_MESSAGE,
      "「以上」は「以下」より小さくしてください"
    ),
    area: makeRangeSchema("「以上」は「以下」より小さくしてください"),
  });

  // 定義したZodのスキーマをTypescriptの型に変換
  type FormData = z.infer<typeof FormDataSchema>;

  // 型を用いてReact-Hook-Formのインスタンスを作る
  const rhf = useForm<FormData>({
    mode: "all",
    defaultValues: {
      prefecture: [],
      city: [],
      areausepurpose: [],
      yousekiRatio: null,
      kenpeiRatio: null,
      realEstateType: [],
      receptionReasons: [],
      ownerName: "",
      ownerAddress: "",
      landType: { farm: false, factoryStorage: false, solar: false },
      estimateAcreage: { min: null, max: null },
      price: { min: null, max: null },
      walk: null,
      buildingType: {
        apartment: false,
        mansion: false,
        building: false,
        jpHotel: false,
        shop: false,
        hotel: false,
        factory: false,
      },
      floorCount: { min: null, max: null },
      buildingVariant: null,
      buildDateRange: [null, null],
      buildingAge: { min: null, max: null },
      area: { min: null, max: null },
    },
    resolver: zodResolver(FormDataSchema),
  });

  const handleSubmit = rhf.handleSubmit(
    (data) => {
      console.log(data);
    },
    (errors) => {
      console.error(errors);
    }
  );

  // 都道府県の変更を検知する
  const prefWatch = rhf.watch("prefecture");

  // 選択している都道府県に応じた市区町村リストを取得する
  const { cityOptions } = useCitySelect(
    prefWatch.map((value) => value.toString()),
    cityParams
  );

  const areausepurpose: ISelectObject[] = [
    { value: "8", label: "近隣商業地域" },
    { value: "1", label: "第一種低層住居専用地域" },
    { value: "4", label: "第一種中高層住居専用地域" },
    { value: "3", label: "第二種住居地域" },
    { value: "6", label: "第一種住居地域" },
    { value: "5", label: "準工業地域" },
    { value: "10", label: "準住居地域" },
    { value: "7", label: "商業地域" },
    { value: "9", label: "第二種低層住居専用地域" },
    { value: "2", label: "工業地域" },
    { value: "11", label: "工業専用地域" },
  ];

  if (!realEstateRegistrationMonitoring) return null;
  return (
    <PagePaper>
      {/* ページタイトル */}
      <Box sx={{ display: "flex", mb: 3 }}>
        <SavedSearchIcon sx={{ mr: 1 }} fontSize="large" />
        <PageTitle>表題部検索</PageTitle>
      </Box>
      <SectionPaper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SectionTitle>絞り込み</SectionTitle>
        </Box>
        <Box sx={{ mx: 2 }}>
          <Divider />
        </Box>
        <Grid sx={{ m: 2 }}>
          <Grid item xs={12}>
            <RowSpacingWrapper>
              <Box sx={{ my: 1 }}>
                <Label>地域</Label>
                <Controller
                  control={rhf.control}
                  name="prefecture"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <MultiSelect
                        label="都道府県"
                        value={value.map((value) => value.toString())}
                        onChange={(value) => {
                          onChange(value.map((value) => Number(value)));
                        }}
                        options={prefectureOptions}
                      />
                    );
                  }}
                />
              </Box>
              <Box sx={{ my: 1, pt: 4 }}>
                <Controller
                  control={rhf.control}
                  name="city"
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <MultiSelect
                        error={Boolean(fieldState.error)}
                        formHelperText={fieldState.error?.message}
                        label="市区町村 *"
                        value={value.map((value) => value.toString())}
                        onChange={(value) => {
                          onChange(value.map((value) => Number(value)));
                        }}
                        options={cityOptions}
                        maxSelect={10}
                      />
                    );
                  }}
                />
              </Box>
              <Box sx={{ my: 1 }}>
                <Label>用途地域</Label>
                <Controller
                  control={rhf.control}
                  name="areausepurpose"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <MultiSelect
                        label="用途地域"
                        value={value.map((value) => value.toString())}
                        onChange={(value) => {
                          onChange(value.map((value) => Number(value)));
                        }}
                        options={areausepurpose}
                        maxSelect={11}
                      />
                    );
                  }}
                />
              </Box>
              <Box sx={{ my: 1 }}>
                <Label>容積率</Label>
                <Controller
                  control={rhf.control}
                  name="yousekiRatio"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select
                        value={value === null ? -1 : value}
                        onChange={(e) => {
                          onChange(Number(e.target.value));
                        }}
                        size="small"
                        sx={{ width: "150px", minHeight: "56px" }}
                        variant={"outlined"}
                      >
                        <MenuItem key={-1} value={-1}>
                          指定なし
                        </MenuItem>
                        {[...Array(1000).keys()]
                          .filter((val) => val >= 50 && val % 5 === 0)
                          .map((val) => (
                            <MenuItem key={val} value={val}>
                              {val}%
                            </MenuItem>
                          ))}
                        <MenuItem key={60} value={60}>
                          1000%以上
                        </MenuItem>
                      </Select>
                    );
                  }}
                />
              </Box>
              <Box sx={{ my: 1 }}>
                <Label>建ぺい率</Label>
                <Controller
                  control={rhf.control}
                  name="kenpeiRatio"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select
                        value={value === null ? -1 : value}
                        onChange={(e) => {
                          onChange(Number(e.target.value));
                        }}
                        size="small"
                        sx={{ width: "150px", minHeight: "56px" }}
                        variant={"outlined"}
                      >
                        <MenuItem key={-1} value={-1}>
                          指定なし
                        </MenuItem>
                        {[...Array(81).keys()]
                          .filter((val) => val >= 30 && val % 5 === 0)
                          .map((val) => (
                            <MenuItem key={val} value={val}>
                              {val}%
                            </MenuItem>
                          ))}
                      </Select>
                    );
                  }}
                />
              </Box>
            </RowSpacingWrapper>
          </Grid>
          <Grid item xs={12}>
            <RowSpacingWrapper>
              <Box sx={{ my: 1 }}>
                <Controller
                  control={rhf.control}
                  name={"realEstateType"}
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <CheckboxGroup
                        label="不動産種別"
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                      >
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Checkbox
                            label="土地"
                            checked={value?.includes("land")}
                            onChange={(checked) => {
                              checked
                                ? onChange([...value, "land"])
                                : onChange(
                                    value.filter((value) => value !== "land")
                                  );
                            }}
                          />
                          <Checkbox
                            label="区分建物"
                            checked={value?.includes("kubunBuilding")}
                            onChange={(checked) => {
                              checked
                                ? onChange([...value, "kubunBuilding"])
                                : onChange(
                                    value.filter(
                                      (value) => value !== "kubunBuilding"
                                    )
                                  );
                            }}
                          />
                          <Checkbox
                            label="建物"
                            checked={value?.includes("building")}
                            onChange={(checked) => {
                              checked
                                ? onChange([...value, "building"])
                                : onChange(
                                    value.filter(
                                      (value) => value !== "building"
                                    )
                                  );
                            }}
                          />
                        </Box>
                      </CheckboxGroup>
                    );
                  }}
                />
              </Box>
              <Box sx={{ my: 1 }}>
                <Label>登記原因</Label>
                <CMultiSelect<FormData>
                  name={"receptionReasons"}
                  control={rhf.control}
                  label="登記原因"
                  options={receptionReasonOptions}
                  maxSelect={receptionReasonOptions.length}
                />
              </Box>
            </RowSpacingWrapper>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Label>所有者名(部分一致)</Label>
                <TextField {...rhf.register("ownerName")} fullWidth={true} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Label>所有者住所(部分一致)</Label>
                <TextField {...rhf.register("ownerAddress")} fullWidth={true} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Accordion sx={{ mb: 1, boxShadow: "none", position: "static" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ flexDirection: "row-reverse" }}
          >
            <Typography sx={{ color: "blue" }}>土地の条件から探す</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid container sx={{ top: "50%" }}>
                <Grid item xs={12}>
                  <CheckboxGroup label="土地種別">
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Controller
                        control={rhf.control}
                        name={"landType"}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <>
                              <Checkbox
                                label="農地"
                                checked={value.farm}
                                onChange={(checked) => {
                                  onChange({ ...value, farm: checked });
                                }}
                              />
                              <Checkbox
                                label="工場・倉庫"
                                checked={value.factoryStorage}
                                onChange={(checked) => {
                                  onChange({
                                    ...value,
                                    factoryStorage: checked,
                                  });
                                }}
                              />
                              <Checkbox
                                label="太陽光"
                                checked={value.solar}
                                onChange={(checked) => {
                                  onChange({ ...value, solar: checked });
                                }}
                              />
                            </>
                          );
                        }}
                      />
                    </Box>
                  </CheckboxGroup>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%", mr: 1 }}>
                <Label>推計地積</Label>
                <Controller
                  control={rhf.control}
                  name={"estimateAcreage"}
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <>
                        <TextField
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={
                            value.min === null || value.min === 0
                              ? ""
                              : value.min
                          }
                          onChange={(event) => {
                            onChange({
                              min: Number(event.target.value),
                              max: value.max,
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          size="small"
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        ㎡以上 〜
                        <TextField
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={
                            value.max === null || value.max === 0
                              ? ""
                              : value.max
                          }
                          onChange={(event) => {
                            onChange({
                              min: value.min,
                              max: Number(event.target.value),
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          size="small"
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        ㎡以下
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>公示価格</Label>
                <Controller
                  control={rhf.control}
                  name={"price"}
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <>
                        <TextField
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={
                            value.min === null || value.min === 0
                              ? ""
                              : value.min
                          }
                          onChange={(event) => {
                            onChange({
                              min: Number(event.target.value),
                              max: value.max,
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          size="small"
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        万円 〜
                        <TextField
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={
                            value.max === null || value.max === 0
                              ? ""
                              : value.max
                          }
                          onChange={(event) => {
                            onChange({
                              min: value.min,
                              max: Number(event.target.value),
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          size="small"
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        万円
                      </>
                    );
                  }}
                />
              </Grid>
            </Box>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>最寄駅からの徒歩時間</Label>
                <Controller
                  name={"walk"}
                  control={rhf.control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select
                        value={value === null ? -1 : value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        size="small"
                        sx={{ width: "150px", minHeight: "56px" }}
                        variant="outlined"
                      >
                        <MenuItem key={-1} value={-1}>
                          指定なし
                        </MenuItem>
                        {[...Array(60).keys()]
                          .filter((val) => val >= 1)
                          .map((val) => (
                            <MenuItem key={val} value={val}>
                              {val}分以内
                            </MenuItem>
                          ))}
                        <MenuItem key={60} value={60}>
                          60分以上
                        </MenuItem>
                      </Select>
                    );
                  }}
                />
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* 不動産種別 */}

        <Accordion sx={{ mb: 1, boxShadow: "none", position: "static" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ flexDirection: "row-reverse" }}
          >
            <Typography sx={{ color: "blue" }}>建物の条件から探す</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {/* 外 */}
            <Box sx={{ mx: 2, mb: 2 }}>
              <CheckboxGroup label="建物種別">
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Controller
                    control={rhf.control}
                    name={"buildingType"}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <>
                          <Checkbox
                            label="マンション"
                            checked={value.mansion}
                            onChange={(checked) => {
                              onChange({ ...value, mansion: checked });
                            }}
                          />
                          <Checkbox
                            label="アパート"
                            checked={value.apartment}
                            onChange={(checked) => {
                              onChange({ ...value, apartment: checked });
                            }}
                          />
                          <Checkbox
                            label="ビル"
                            checked={value.building}
                            onChange={(checked) => {
                              onChange({ ...value, building: checked });
                            }}
                          />
                          <Checkbox
                            label="旅館"
                            checked={value.jpHotel}
                            onChange={(checked) => {
                              onChange({ ...value, jpHotel: checked });
                            }}
                          />
                          <Checkbox
                            label="店舗"
                            checked={value.shop}
                            onChange={(checked) => {
                              onChange({ ...value, shop: checked });
                            }}
                          />
                          <Checkbox
                            label="ホテル"
                            checked={value.hotel}
                            onChange={(checked) => {
                              onChange({ ...value, hotel: checked });
                            }}
                          />
                          <Checkbox
                            label="工場"
                            checked={value.factory}
                            onChange={(checked) => {
                              onChange({ ...value, factory: checked });
                            }}
                          />
                        </>
                      );
                    }}
                  />
                </Box>
              </CheckboxGroup>
            </Box>

            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>階数</Label>
                <Controller
                  control={rhf.control}
                  name={"floorCount"}
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <>
                        <TextField
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          value={
                            value.min === null || value.min === 0
                              ? ""
                              : value.min
                          }
                          onChange={(event) => {
                            onChange({
                              min: Number(event.target.value),
                              max: value.max,
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          size="small"
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        階以上 〜
                        <TextField
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          value={
                            value.max === null || value.max === 0
                              ? ""
                              : value.max
                          }
                          onChange={(event) => {
                            onChange({
                              min: value.min,
                              max: Number(event.target.value),
                            });
                          }}
                          onKeyDown={undefined}
                          onPaste={undefined}
                          size="small"
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        階以下
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid sx={{ display: "inline-block", top: "50%", ml: 1 }}>
                <Label>構造</Label>
                <Controller
                  name={"buildingVariant"}
                  control={rhf.control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select
                        value={value === null ? -1 : value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        size="small"
                        sx={{ width: "150px", minHeight: "56px" }}
                        variant="outlined"
                      >
                        <MenuItem key={-1} value={-1}>
                          指定なし
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          木造
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          CB
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          CFT
                        </MenuItem>
                        <MenuItem key={4} value={4}>
                          鉄骨造
                        </MenuItem>
                        <MenuItem key={5} value={5}>
                          RC
                        </MenuItem>
                        <MenuItem key={6} value={6}>
                          SRC
                        </MenuItem>
                        <MenuItem key={7} value={7}>
                          PC
                        </MenuItem>
                        <MenuItem key={8} value={8}>
                          HPC
                        </MenuItem>
                        <MenuItem key={9} value={9}>
                          AL
                        </MenuItem>
                        <MenuItem key={10} value={10}>
                          ALC
                        </MenuItem>
                        <MenuItem key={11} value={11}>
                          鉄筋ブロック造
                        </MenuItem>
                        <MenuItem key={12} value={12}>
                          その他
                        </MenuItem>
                      </Select>
                    );
                  }}
                />
              </Grid>
            </Box>

            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%", mr: 1 }}>
                <Label>築年月</Label>
                <Box sx={{ display: "flex" }}>
                  <Controller
                    control={rhf.control}
                    name={"buildDateRange"}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <DateRangePicker
                          startDateLabel="以降"
                          startDate={value[0]}
                          onChangeStartDate={(date) => {
                            onChange([date, value[1]]);
                          }}
                          endDateLabel="以前"
                          endDate={value[1]}
                          maxDate={maxDate}
                          onChangeEndDate={(date) => {
                            onChange([value[0], date]);
                          }}
                          inputFormat="yyyy年"
                          views={["year"]}
                        />
                      );
                    }}
                  />
                </Box>
              </Grid>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>築年数</Label>
                <Controller
                  name="buildingAge"
                  control={rhf.control}
                  render={({ field, formState }) => (
                    <TextField
                      {...field}
                      value={
                        field.value.min === null || field.value.min === 0
                          ? ""
                          : field.value.min
                      }
                      id="buildingAgeMin"
                      label="年以上"
                      type="number"
                      sx={{ mr: 1 }}
                      error={!!formState.errors.buildingAge?.min}
                      helperText={
                        formState.errors.buildingAge?.min
                          ? formState.errors.buildingAge.min.message
                          : ""
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = Number(event.target.value);
                        field.onChange({
                          min: isNaN(value) ? null : value,
                          max: field.value.max,
                        });
                      }}
                      onKeyDown={undefined}
                      onPaste={undefined}
                    />
                  )}
                />
                <Controller
                  name="buildingAge"
                  control={rhf.control}
                  render={({ field, formState }) => (
                    <TextField
                      {...field}
                      value={
                        field.value.max === null || field.value.max === 0
                          ? ""
                          : field.value.max
                      }
                      id="buildingAgeMax"
                      label="年以下"
                      type="number"
                      error={!!formState.errors.buildingAge?.max}
                      helperText={
                        formState.errors.buildingAge?.max
                          ? formState.errors.buildingAge.max.message
                          : ""
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = Number(event.target.value);
                        field.onChange({
                          min: field.value.min,
                          max: isNaN(value) ? null : value,
                        });
                      }}
                      onKeyDown={preventNonNumericValueWhenKeyDown}
                      onPaste={preventNonNumericValueWhenPaste}
                    />
                  )}
                />
              </Grid>
            </Box>

            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>占有面積</Label>
                <Controller
                  control={rhf.control}
                  name={"area"}
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <>
                        <TextField
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={
                            value.min === 0 || value.min === null
                              ? ""
                              : value.min
                          }
                          onChange={(event) => {
                            onChange({
                              min: Number(event.target.value),
                              max: value.max,
                            });
                          }}
                          onKeyDown={preventNonNumericValueWhenKeyDown}
                          onPaste={preventNonNumericValueWhenPaste}
                          size="small"
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        ㎡以上 〜
                        <TextField
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={
                            value.max === 0 || value.max === null
                              ? ""
                              : value.max
                          }
                          onChange={(event) => {
                            onChange({
                              min: value.min,
                              max: Number(event.target.value),
                            });
                          }}
                          onKeyDown={preventNonNumericValueWhenKeyDown}
                          onPaste={preventNonNumericValueWhenPaste}
                          size="small"
                          sx={{ width: "150px", top: "50%" }}
                          inputProps={{
                            min: 0,
                            type: "number",
                            step: "10",
                          }}
                        />
                        ㎡以下
                      </>
                    );
                  }}
                />
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Box sx={{ my: 3, mr: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            label="この条件で検索"
            onClick={() => {
              (async () => {
                await handleSubmit();
              })();
            }}
            variant={ButtonVariantOption.Contained}
            size={SizingWrapperStyle.SMALL}
          />
        </Box>
      </SectionPaper>

      <Box sx={{ mt: 3 }} />

      {/* 不動産情報一覧テーブル */}
      <SectionPaper>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <SectionTitle>不動産情報一覧</SectionTitle>
          <Stack mx={2} my={2} direction="row" spacing={1}>
            <MuiButton
              onClick={undefined}
              variant="contained"
              sx={{ fontWeight: "bold" }}
            >
              不動産登記/図面
              <br />
              取得（一括）
            </MuiButton>
          </Stack>
        </Stack>

        <FeedGrid />

        <Box sx={{ mb: 6 }} />
      </SectionPaper>

      {/* 戻る */}
      <Box sx={{ mt: 2 }}>
        <Link
          href={mypageTop ? "/mypage" : "/feed"}
          sx={{ display: "inline-block" }}
        >
          <Box sx={{ display: "flex" }}>
            <KeyboardArrowLeft />
            <Typography>
              {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
            </Typography>
          </Box>
        </Link>
      </Box>
      {/* モーダルを表示する際に使用するコンポーネント */}
      <RealEstateDetailModal
        open={true}
        fullWidth={true}
        maxWidth={"md"}
        title={"不動産情報詳細"}
        contents={[
          { title: "aaa", value: "bbb" },
          { title: "ほげほげ", value: "うあうあう" },
        ]}
      />
    </PagePaper>
  );
};
