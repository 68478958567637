import React from "react";
import { StyledDataGrid } from "../DataGrid/KaokuNumberSearchStyleDataGrid";
import {
  type GridColDef,
  type GridSelectionModel,
  type GridRenderCellParams,
} from "@mui/x-data-grid";
import { NYHalfWidthNumberToFillWidthNumber } from "@/utils/utils";
import { type ChibanRow } from "@/types/kaokuNumber";

interface Props {
  handleRowSelectionChange: (newSelectionModel: GridSelectionModel) => void;
  rows: ChibanRow[];
  selectedRowIds: GridSelectionModel;
}

/**
 * 地番のリスト部分のコンポーネント
 * @param {Props} props
 * @returns {React.FC<Props>}
 */
export const StyledDataGridChiban: React.FC<Props> = (props) => {
  const { rows, handleRowSelectionChange, selectedRowIds } = props;
  const columns: GridColDef[] = [
    {
      field: "prefectureName",
      headerName: "都道府県",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "所在",
      width: 400,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "chiban",
      headerName: "地番",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        return NYHalfWidthNumberToFillWidthNumber(value as string);
      },
    },
  ];

  return (
    <StyledDataGrid
      rowHeight={36}
      headerHeight={40}
      pageSize={30}
      hideFooter
      disableColumnMenu
      disableSelectionOnClick={true}
      columns={columns}
      rows={rows}
      checkboxSelection
      selectionModel={selectedRowIds}
      onSelectionModelChange={handleRowSelectionChange}
    />
  );
};
