import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  type DrawCreatedEvent,
  type GeoSpaceChiban,
  type PropertySelectionRow,
  type GeoSpacePolygonItem,
  type MarkerState,
  type PolygonParams,
  type CircleParams,
} from "../types";
import {
  FeatureGroup,
  LayersControl,
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, {
  LatLng,
  type Layer,
  type Map,
  type Popup as LeafletPopup,
  type LeafletMouseEvent,
  type LeafletEvent,
} from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { GeospaceChibanTileLayer } from "@/features/map/components/GeospaceChibanTileLayer";
import { Button, ButtonVariantOption } from "@/components/Button";
import { renderToString } from "react-dom/server";
import { Box, Modal } from "@mui/material";
import {
  AreaUsePurposeLayerControlWrapper,
  AREA_USE_PURPOSE_LAYER_NAME,
} from "./AreaUsePurposeLayerControlWrapper";
import { useMapServicePermission } from "@/hooks/useMapServicePermission";
import { EditControl } from "react-leaflet-draw";
import { useGeoSpaceApi } from "../hooks/useGeospaceSearch";
import { type BookTypeEng } from "@/types/acquirebook";
import { type Id, toast } from "react-toastify";
import "leaflet-geometryutil";
import { RangeSelectResultModal } from "./RangeSelectResultModal";
import { ZenrinTileLayer } from "@/features/map/components/ZenrinTileLayer";
import { useFeatureFlags } from "@/configs/featureFlag";
import { skipSelectChiban } from "../utils";
import { LegendsControl } from "./Legend";
import { CustomControl } from "./CustomControl";
import {
  HazardFlood10Layer,
  FLOOD_10_LAYER_NAME,
} from "./layers/HazardFlood10Layer";
import {
  HazardFlood20Layer,
  FLOOD_20_LAYER_NAME,
} from "./layers/HazardFlood20Layer";
import {
  HazardFlood30Layer,
  FLOOD_30_LAYER_NAME,
} from "./layers/HazardFlood30Layer";

const MAX_SEARCH_AREA_SQUARE_METERS = 160000;
const MAX_SEARCH_RADIUS_METERS = 200;
const MAX_SEARCH_VERTEX = 10;
const MAX_SEARCH_DATA = 50;

// ピンのデフォルト画像を設定する
const DefaultIcon = L.icon({
  iconUrl: icon,
  iconRetinaUrl: iconRetina,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

/**
 * 新しいMarkerStateと古いMarkerStateの差分を抽出する
 * MarkerStateの中の_idが一致するかどうかで差分検知をしている
 * @param newStates 新しいMarkerState配列
 * @param oldStates 更新前のMarkerState配列
 */
const extractNewMarkerStates = (
  newStates: MarkerState[],
  oldStates: MarkerState[]
): MarkerState[] => {
  const result: MarkerState[] = [];
  newStates.forEach((newItem) => {
    if (!oldStates.some((oldItem) => oldItem._id === newItem._id)) {
      result.push(newItem);
    }
  });
  // console.log("差分", result);
  return result;
};

/**
 * ポップアップ付きのピンを生成する
 * @param content Reactコンポーネント
 * @param latlng 表示する座標
 * 生成できない場合はnullを返す
 */
const makePopupMarker = ({ content, latlng }: MarkerState): null | L.Marker => {
  if (!latlng) return null;
  if (!content) return null;

  const p1 = L.popup({
    content: renderToString(content as React.ReactElement),
  });
  return new L.Marker(latlng).bindPopup(p1);
};

/**
 * ピンのリストを生成する
 * ピンを生成した時nullが返ってきた場合はリストから取り除く
 * @param states
 */
const makePopupMarkerListWithMarkerStates = (
  states: MarkerState[]
): L.Marker[] => {
  const markers =
    states.map((markerState) => {
      return makePopupMarker(markerState);
    }) ?? [];
  // nullになったピンは取り除く
  return markers?.filter((marker): marker is L.Marker => marker !== null);
};

interface Props {
  clickedAreaPolygonData: GeoSpacePolygonItem[] | undefined;
  polygonData: GeoSpacePolygonItem[][] | undefined;
  viewLatLng: LatLng;
  setClickedLatLng: Dispatch<SetStateAction<LatLng | null>>;
  markerState: MarkerState;
  receptionBookMarkerStates?: MarkerState[];
  setSelectedProperties: React.Dispatch<
    React.SetStateAction<PropertySelectionRow[]>
  >;
  setPolygonData: React.Dispatch<SetStateAction<LatLng[]>>;
  buildLocationString: (r: GeoSpaceChiban) => string;
  checkDuplicateProperties: (
    rows: PropertySelectionRow[],
    bookType: BookTypeEng,
    prefName: string,
    location: string,
    chiban: string
  ) => boolean;
  setMarkerState: React.Dispatch<React.SetStateAction<MarkerState>>;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
}

export const INITIAL_ZOOM = 17;
const MAX_ZOOM = 19;

export const LeafletMap: React.FC<Props> = ({
  clickedAreaPolygonData,
  polygonData,
  viewLatLng,
  setClickedLatLng,
  markerState,
  receptionBookMarkerStates,
  setSelectedProperties,
  setPolygonData,
  checkDuplicateProperties,
  buildLocationString,
  setMarkerState,
  setZoom,
}) => {
  const containerStyle = {
    height: "83vh",
    width: "100%",
    display: "inline-block",
  };

  const [map, setMap] = useState<Map | null>(null);
  const [popupRef, setPopupRef] = useState<LeafletPopup | null>(null);
  const [layerControl, setLayerControl] = useState<L.Control.Layers | null>(
    null
  );

  // 地図インポートで立てるピンをまとめて保持するLayerGroup
  const [layerGroup, setLayerGroup] = useState<L.LayerGroup | null>(null);

  // 地番地図のAPP IDを取得
  const mapServiceAppId = localStorage.getItem("mapServiceAppId");

  // 古いreceptionBookMarkerStatesを保存しておくRef
  // refなので値が更新されても再レンダリングはしない
  const oldReceptionBookMarkerStates = useRef<MarkerState[]>([]);

  // receptionBookMarkerStatesの差分を保存する配列
  let diffReceptionBookMarkerStates: MarkerState[] = [];

  // receptionBookMarkerStatesの差分を検知して抽出し、旧値をoldReceptionBookMarkerStatesに保存する
  if (receptionBookMarkerStates) {
    if (
      receptionBookMarkerStates.length !==
      oldReceptionBookMarkerStates.current.length
    ) {
      diffReceptionBookMarkerStates = extractNewMarkerStates(
        receptionBookMarkerStates,
        oldReceptionBookMarkerStates.current
      );
      oldReceptionBookMarkerStates.current = receptionBookMarkerStates;
    }
  }

  // ---- ここからデバッグ用コード ----
  const [debugVisible, setDebugVisible] = useState<boolean>(false);
  interface DebugViewProps {
    visible: boolean;
  }
  const DebugView: React.FC<DebugViewProps> = ({ visible }) => {
    if (visible) {
      return (
        <Box sx={{ my: 1 }}>
          <Button
            label={"地図を東京へ移動"}
            variant={ButtonVariantOption.Outlined}
            onClick={() => {
              map?.setView(
                new LatLng(35.695530421125156, 139.78227391979706),
                13
              );
            }}
          />
        </Box>
      );
    }
    return null;
  };

  // コンソールで"debug()"と入力するとデバッグモードを有効にすることが可能です
  Object.assign(window, {
    debug: (): void => {
      console.log("debug ui on");
      setDebugVisible(true);

      // 現在のズームレベルをコンソールに表示する
      map?.on("zoomend", function () {
        console.log("ZoomLevel:", map?.getZoom());
      });
      // 中心の座標を表示
      map?.on("moveend", function () {
        console.log("中心座標", map?.getCenter());
      });
    },
  });
  // ---- デバッグ用コードここまで ----

  // 受付帳のマーカー情報が渡されている時はピンを立てる処理を実行する
  if (diffReceptionBookMarkerStates.length > 0) {
    // 初回のピン立て、ピンをまとめるレイヤーグループを生成する
    if (layerGroup === null) {
      const markers = makePopupMarkerListWithMarkerStates(
        diffReceptionBookMarkerStates
      );
      const lg = L.layerGroup(markers);
      setLayerGroup(lg);
      // lgを右上のレイヤーメニューに追加
      layerControl?.addOverlay(lg, "受付帳情報ピンを表示");
      map?.addLayer(lg);
      map?.setView(markers[0].getLatLng());
      map?.setZoom(14);
    } else {
      // レイヤーグループが存在する場合はMarkerStateの差分を新規追加
      makePopupMarkerListWithMarkerStates(
        diffReceptionBookMarkerStates
      ).forEach((item) => {
        layerGroup.addLayer(item);
      });
      // console.log("表示中レイヤー", layerGroup.getLayers());
    }
  }

  // 図形描画中はクリックイベントを無効化
  const [isDrawing, setIsDrawing] = useState(false);
  useEffect(() => {
    if (!map) return;

    // Leaflet Drawイベントリスナーを設定（描画開始、終了を監視）
    map.on(L.Draw.Event.DRAWSTART, () => {
      setIsDrawing(true); // 描画中フラグをtrueに設定
    });

    map.on(L.Draw.Event.DRAWSTOP, () => {
      setIsDrawing(false); // 描画中フラグをfalseに設定
    });

    return () => {
      map.off("click"); // イベントリスナーをクリーンアップ
    };
  }, [map, isDrawing]);

  map?.addEventListener("click", (event) => {
    if (isDrawing) {
      return; // 描画中は処理をスキップ
    }
    // マーカー位置の設定
    markerState.latlng = event.latlng;
    // clickedLatLngを更新すると、親コンポーネント内で自動的にAPIが呼び出されてPopupのデータが更新される
    setClickedLatLng(event.latlng);
  });

  // ズーム後に実行される。
  map?.on("zoomend", (event: LeafletEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setZoom(event.target.getZoom());
  });

  // 表示座標のStateが更新された時に実行する
  // 主にUI側から検索ボタンが押された時に実行される
  useEffect(() => {
    map?.setView(viewLatLng, 19);
  }, [viewLatLng]);

  // ポップアップ設定が変更された時はポップアップを表示する
  useEffect(() => {
    if (map && markerState.content) {
      popupRef?.openOn(map);
    }
  }, [markerState]);

  const [hasMapViewingPermission] = useMapServicePermission();
  const { mapZenrin } = useFeatureFlags();

  // ユーザーがポリゴンを地図に描画した際、範囲内の地番を取得する
  const drawnItemsRef = useRef(L.featureGroup());
  const { geospaceReverseByPolygon, geospaceReverseByCircle } =
    useGeoSpaceApi();

  const [geospaceReverseByShapeResult, setGeospaceReverseByShapeResult] =
    useState<GeoSpaceChiban | undefined>(undefined);

  const [toastId, setToastId] = useState<Id | null>(null);
  const [RangeSelectModalIsOpen, setRangeSelectModalIsOpen] =
    useState<boolean>(false);
  const [newProperty, setNewProperty] = useState<PropertySelectionRow[]>([]);

  const handleDrawResult = (
    result: GeoSpaceChiban | undefined,
    layer: Layer,
    toastId: Id | null
  ): void => {
    if (Array.isArray(result)) {
      const hasValidChiban = result.some(
        (item: GeoSpaceChiban) =>
          !/^[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF a-zA-Z]+$/.test(
            item.chiban
          )
      );

      if (hasValidChiban) {
        setGeospaceReverseByShapeResult(result);
        drawnItemsRef.current.removeLayer(layer);
        setRangeSelectModalIsOpen(true);
      } else {
        toast.info("有効な地番が存在しませんでした。");
        drawnItemsRef.current.removeLayer(layer);
      }
    } else {
      drawnItemsRef.current.removeLayer(layer);
      toast.dismiss(toastId ?? undefined);
      toast.info("データが存在しませんでした");
    }
  };

  const handleDrawCreated = (e: DrawCreatedEvent): void => {
    const layer = e.layer as Layer;
    drawnItemsRef.current.addLayer(layer);

    // onCreatedはvoidの型を期待するため、関数内に非同期関数を定義
    const processLayer = async (): Promise<void> => {
      const fetchCircleData = async (params: CircleParams): Promise<void> => {
        const { radius, lat, lng } = params;
        const id = toast.info("選択範囲のデータを取得中...", {
          autoClose: false,
        });
        setToastId(id);

        const result: GeoSpaceChiban | undefined =
          await geospaceReverseByCircle(radius, lat, lng);

        handleDrawResult(result, layer, id);

        toast.dismiss(id);
      };

      const fetchPolygonData = async (params: PolygonParams): Promise<void> => {
        const { polygon } = params;
        const id = toast.info("選択範囲のデータを取得中...", {
          autoClose: false,
        });
        setToastId(id);

        const result = await geospaceReverseByPolygon(polygon);

        handleDrawResult(result, layer, id);

        toast.dismiss(id);
      };

      if (layer instanceof L.Circle) {
        const { lat, lng } = layer.getLatLng();
        const radius = layer.getRadius();
        if (radius <= MAX_SEARCH_RADIUS_METERS) {
          await fetchCircleData({ radius, lat, lng });
        } else {
          drawnItemsRef.current.removeLayer(layer);
          toast.info(
            `円の半径は${MAX_SEARCH_RADIUS_METERS}m以内で描画してください`
          );
        }
      } else {
        const polygonLayer = layer as L.Polygon;

        const latLngs = polygonLayer.getLatLngs();
        const polygonCoordinates = (latLngs as L.LatLng[][])[0];

        const shapeArea: number =
          L.GeometryUtil.geodesicArea(polygonCoordinates);

        const vertexCount = polygonCoordinates.length;

        if (vertexCount <= MAX_SEARCH_VERTEX) {
          if (shapeArea <= MAX_SEARCH_AREA_SQUARE_METERS) {
            const polygon: string = polygonCoordinates
              .map(({ lng, lat }: L.LatLng) => `${lng},${lat}`)
              .concat(
                `${polygonCoordinates[0].lng},${polygonCoordinates[0].lat}`
              )
              .join(",");
            await fetchPolygonData({ polygon });
          } else {
            drawnItemsRef.current.removeLayer(layer);
            toast.info(
              `図形は${MAX_SEARCH_AREA_SQUARE_METERS}㎡以内で描画してください`
            );
          }
        } else {
          drawnItemsRef.current.removeLayer(layer);
          toast.info("図形は十角形以内で描画してください");
        }
      }
    };
    processLayer().catch(() => {
      drawnItemsRef.current.removeLayer(layer);
      toast.error("データの取得中にエラーが発生しました。");
    });
  };

  // 取得した地番データをモーダルのテーブルに反映
  useEffect(() => {
    const processProperties = (): void => {
      if (!geospaceReverseByShapeResult) return;

      if (Array.isArray(geospaceReverseByShapeResult)) {
        geospaceReverseByShapeResult.forEach((result: GeoSpaceChiban) => {
          // 日本語の文字またはアルファベットを含む場合はスキップ（CHIBAN_FULL_PATTERNは除く）
          if (skipSelectChiban(result.chiban)) {
            return;
          }

          setNewProperty((prev) => {
            const bookType = "LAND" as BookTypeEng;

            const lastId: number =
              prev.length > 0 ? prev[prev.length - 1].id : 0;
            return [
              ...prev,
              {
                id: lastId + 1,
                selected: true,
                bookType,
                prefName: result.prefName,
                location: buildLocationString(result),
                chibanKaokuNumber: result.chiban,
                area: result.area,
                isFeedOrigin: false,
                rawData: result,
              },
            ].sort((a, b) => a.id - b.id);
          });
        });
        toast.dismiss(toastId ?? undefined);
        toast.info("選択範囲のデータ取得が完了しました");
      } else {
        setPolygonData([]);
        setMarkerState((prevState) => ({
          ...prevState,
          content: null,
        }));
        toast.dismiss(toastId ?? undefined);
        toast.info("選択範囲のデータが存在しませんでした");
      }
    };
    processProperties();
  }, [geospaceReverseByShapeResult]);

  // Leaflet Drawで円を描画する際に、コンポーネント内で起きるtypeerrorを表示しない
  useEffect(() => {
    // マウント時の処理
    const originalOnError = window.onerror;

    window.onerror = function (message) {
      if (
        typeof message === "string" &&
        message.includes("toFixed is not a function")
      ) {
        // エラーを無視し、コンソールへの出力を抑制
        return true;
      }
      return false; // デフォルトのエラーハンドリングを続行
    };

    // アンマウント時の処理（クリーンアップ）
    return () => {
      // window.onerror を元のハンドラに戻す
      window.onerror = originalOnError;
    };
  }, []);

  // 円と多角形に関連する部分のみ日本語化
  useEffect(() => {
    Object.assign(L.drawLocal.draw.toolbar.buttons, {
      circle: "円を描画して、範囲選択",
      polygon: "多角形を描画して、範囲選択",
    });

    Object.assign(L.drawLocal.draw.handlers.circle.tooltip, {
      start: "クリックして円を描画",
      line: "ドラッグして円の半径を設定",
      end: "マウスを離して円の描画を完了",
    });

    Object.assign(L.drawLocal.draw.handlers.circle, {
      radius: "半径",
    });

    Object.assign(L.drawLocal.draw.handlers.polygon, {
      area: "面積",
    });

    Object.assign(L.drawLocal.draw.toolbar.actions, {
      title: "描画をキャンセル",
      text: "キャンセル",
    });

    Object.assign(L.drawLocal.draw.toolbar.finish, {
      title: "描画を完了",
      text: "完了",
    });

    Object.assign(L.drawLocal.draw.handlers.polygon.tooltip, {
      start: "クリックして描画を開始",
      cont: "クリックして頂点を追加",
      end: "最初のポイントをクリックして多角形を閉じる",
    });

    Object.assign(L.drawLocal.draw.toolbar.undo, {
      title: "最後のポイントを削除",
      text: "最後のポイントを削除",
    });
    Object.assign(L.drawLocal.draw.handlers.polyline, {
      error: "三角形以上で描画してください",
    });
  }, []);

  return (
    <>
      <DebugView visible={debugVisible} />
      <MapContainer
        ref={setMap}
        center={viewLatLng}
        zoom={INITIAL_ZOOM}
        scrollWheelZoom={true}
        style={containerStyle}
        maxZoom={mapZenrin ? 22 : MAX_ZOOM}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxZoom={19}
        />
        <FeatureGroup ref={drawnItemsRef}>
          <EditControl
            position="topleft"
            onCreated={handleDrawCreated}
            draw={{
              polygon: {
                shapeOptions: {
                  stroke: true,
                  color: "#ff0000",
                  weight: 5,
                  opacity: 0.5,
                  fill: true,
                  fillColor: "#ffcccc",
                  fillOpacity: 0.5,
                  clickable: true,
                },
              },
              circle: {
                shapeOptions: {
                  stroke: true,
                  color: "#ff0000",
                  weight: 5,
                  opacity: 0.5,
                  fill: true,
                  fillColor: "#ffcccc",
                  fillOpacity: 0.5,
                  // react-leaflet-drawの型定義はleaflet-drawのものであり、clickableからinteractiveに移行済み
                  // しかし、react-leaflet-drawが実装に使用しているleaflet-drawはclickableからinteractiveに移行していない
                  // そのためclickableを使用するが、型エラーが発生するため意図的にエラーを無視するように実装
                  // @ts-expect-error: 上記の理由により型エラーを許容
                  clickable: true,
                },
              },
              marker: false,
              polyline: false,
              rectangle: false,
              circlemarker: false,
            }}
            edit={{
              edit: false,
              remove: false,
            }}
          />
        </FeatureGroup>
        <LayersControl ref={setLayerControl}>
          {hasMapViewingPermission && (
            <LayersControl.Overlay name="地番を表示する" checked={true}>
              {mapServiceAppId && (
                <GeospaceChibanTileLayer mapServiceAppId={mapServiceAppId} />
              )}
            </LayersControl.Overlay>
          )}
          <LayersControl.Overlay
            name={AREA_USE_PURPOSE_LAYER_NAME}
            checked={false}
          >
            <AreaUsePurposeLayerControlWrapper
              defaultVisible={false}
              initialZoomLevel={INITIAL_ZOOM}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name={FLOOD_10_LAYER_NAME} checked={false}>
            <HazardFlood10Layer defaultVisible={false} />
          </LayersControl.Overlay>
          <LayersControl.Overlay name={FLOOD_20_LAYER_NAME} checked={false}>
            <HazardFlood20Layer defaultVisible={false} />
          </LayersControl.Overlay>
          <LayersControl.Overlay name={FLOOD_30_LAYER_NAME} checked={false}>
            <HazardFlood30Layer defaultVisible={false} />
          </LayersControl.Overlay>
          {mapZenrin && (
            <LayersControl.Overlay name="住宅地図" checked={false}>
              {mapServiceAppId && (
                <ZenrinTileLayer
                  attribution={
                    "Copyright © ZENRIN CO., LTD. All Rights Reserved."
                  }
                  url={
                    "https://test-web.zmaps-api.com/map/wmts_tile/ZpcFaeMB/default/Z3857_3_21/{z}/{y}/{x}.png"
                  }
                  maxZoom={22}
                  minZoom={3}
                />
              )}
            </LayersControl.Overlay>
          )}
        </LayersControl>
        <CustomControl prepend={true} position="bottomright">
          <LegendsControl />
        </CustomControl>

        <Marker
          position={markerState.latlng ?? viewLatLng}
          eventHandlers={{
            click: (event: LeafletMouseEvent) => {
              if (!markerState.content) {
                (event.target as L.Marker).closePopup();
              }
            },
          }}
        >
          <Popup ref={setPopupRef}>{markerState.content}</Popup>
        </Marker>
        <Polygon positions={polygonData as LatLng[][]} />
        <Polygon
          positions={clickedAreaPolygonData as LatLng[]}
          pathOptions={{ color: "green", fill: false }}
        />
        <Modal
          open={RangeSelectModalIsOpen}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "60%",
              boxShadow: 24,
              borderRadius: 2,
              outline: "none",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <RangeSelectResultModal
              rows={newProperty}
              setRows={setNewProperty}
              setSelectedProperties={setSelectedProperties}
              setRangeSelectModalIsOpen={setRangeSelectModalIsOpen}
              checkDuplicateProperties={checkDuplicateProperties}
              buildLocationString={buildLocationString}
              geospaceReverseByShapeResult={geospaceReverseByShapeResult}
            />
          </Box>
        </Modal>
      </MapContainer>
    </>
  );
};
