import { styled } from "@mui/material/styles";
import { AcquireBookDataGrid } from "@/components/DataGrid";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { useApiClient } from "@/hooks/useApiClient";
import { type AxiosInstance } from "axios";
import useSWR from "swr";
import { ButtonVariantOption, LinkButton } from "../../../components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";

interface TitleSearchDataGridRow {
  id: number;
  realestateType: string;
  prefecture: string;
  syozai: string;
  chibanKaokuNo: string;
  action?: boolean;
}

interface PictureRequestAPIRequestStatus {
  id: number;
  task_id: string;
  status: string;
  name: string;
  requestPictureCount: number;
  requestedAt: Date;
  finishedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

interface PictureRequestAPIResponse {
  count: number;
  list: PictureRequestAPIRequestStatus[];
}

const StyledDataGrid = styled(AcquireBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (): GridColDef[] => {
  return [
    {
      field: "realestateType",
      headerName: "不動産種別",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "prefecture",
      headerName: "都道府県",
      width: 90,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "syozai",
      headerName: "所在",
      width: 300,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "chibanKaokuNo",
      headerName: "地番または家屋番号",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ id }: GridRenderCellParams<string>) => {
        return (
          <LinkButton
            variant={ButtonVariantOption.Contained}
            href={"/"}
            wrapperSize={SizingWrapperStyle.SMALL}
          >
            詳細
          </LinkButton>
        );
      },
    },
  ];
};

// const usePictureRequest = (
//   apiClient: AxiosInstance
// ): AcquireBookStatusDataGridRow[] => {
//   const fetcher = async (url: string): Promise<PictureRequestAPIResponse> => {
//     const { data } = await apiClient.get<PictureRequestAPIResponse>(url);
//     return data;
//   };
//
//   const { data } = useSWR<PictureRequestAPIResponse>(
//     "/picture-request/",
//     fetcher,
//     { revalidateOnFocus: true }
//   );
//
//   if (data === undefined) return [];
//   return data.list;
// };

export const FeedGrid: React.FC = () => {
  // const { apiClient } = useApiClient();
  const colDef = makeGridColDef();

  // const rows = usePictureRequest(apiClient);
  const rows: TitleSearchDataGridRow[] = [
    {
      id: 13,
      realestateType: "建物",
      prefecture: "東京都",
      syozai: "千代田区1丁目1",
      chibanKaokuNo: "28-4",
      action: false,
    },
  ];

  return (
    <StyledDataGrid
      autoHeight
      checkboxSelection
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={rows}
      columns={colDef}
      sortModel={[
        {
          field: "requestedAt",
          sort: "desc",
        },
      ]}
    />
  );
};
