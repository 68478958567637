import { type ReceptionReasonEntity } from "@/features/monitoring/types";

export const getDisplayReceptionReason = (
  receptionReasons: ReceptionReasonEntity[]
): string => {
  return receptionReasons
    .sort((a, b) => a.id - b.id)
    .map((receptionReason) => receptionReason.receptionReason)
    .join(" / ");
};
