import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Button,
  ButtonVariantOption,
  SubmitButton,
} from "../../../components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { KeyboardArrowLeft } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import React, { useState } from "react";
import { useValidator } from "../hooks/useValidator";
import { FileUploadButton } from "@/features/realEstateReceptionBookFeed/components/FileUploadButton";
import {
  preventNonNumericValueWhenPaste,
  preventNonNumericValueWithoutBackspaceWhenKeyDown,
} from "@/utils/utils";
import { useApiClient } from "@/hooks/useApiClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { type HandOffMessage } from "@/types/handOffMessage";
import { isAxiosError } from "axios";
import { useFeatureFlags } from "@/configs/featureFlag";

interface UploadResponse {
  nonFieldErrors: string[];
}

export const AllMattersPDFUpload: React.FC = () => {
  // 識別番号
  const [identifierNumber, setIdentifierNumber] = useState<string>("");
  // 識別名
  const [identifierName, setIdentifierName] = useState<string>("");

  // 確認画面のモーダルを表示するかしないかを決めるフラグ
  const [openModalFlag, setOpenModalFlag] = React.useState(false);

  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);

  // バリデーションのためのフックを導入する
  const validator = useValidator();

  const navigate = useNavigate();
  const { apiClient } = useApiClient();
  const { mypageTop } = useFeatureFlags();

  const handleFileUploadButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cancelLoading: () => void
  ): void => {
    if (fileToUpload) {
      const formData = new FormData();
      formData.append("file", fileToUpload);
      formData.append("task_id", identifierNumber);
      formData.append("identification_name", identifierName);
      apiClient
        .post("/owner-analyze/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .catch((e) => {
          cancelLoading();
          // レスポンスの結果が502の時はエラーを無視する
          if (
            isAxiosError<UploadResponse>(e) &&
            e.response?.status != null &&
            e.response?.status > 300 &&
            e.response?.status !== 502
          ) {
            console.error(e);
            let isSetMessage = false;
            if (e.response?.status === 400) {
              const data = e.response?.data;
              const nonFieldErrors = data.nonFieldErrors || [];
              if (
                nonFieldErrors.length > 0 &&
                nonFieldErrors[0].startsWith(
                  "OWNER_ANALYZE_PDF_MAX_NUMBER_ERROR"
                )
              ) {
                const maxNum: string =
                  nonFieldErrors[0].split(",").at(-1) ?? "100";
                isSetMessage = true;
                toast.error(
                  <>
                    PDFアップロードの上限{maxNum}件を超えました。
                    <br />
                    {maxNum}件以内にして再度アップロードしてください。
                  </>,
                  { autoClose: false }
                );
              }
            }
            if (!isSetMessage) {
              toast.error("全部事項解析ファイルのアップロードに失敗しました"); // 非同期で次画面にいくがエラーMSGは表示される
            }
          }
        });

      // POST結果を待たずに、非同期で遷移する
      const handOffMessage: HandOffMessage = {
        command: "ALL_MATTERS_SHOW_ACCEPT_MESSAGE",
      };
      navigate("/allmatters/list", {
        replace: true,
        state: { handOffMessage },
      });
    }
  };

  return (
    <PagePaper>
      <form>
        {/* ページタイトル */}
        <Stack direction={"row"} justifyContent="space-between">
          <Box>
            <Box sx={{ display: "flex", mb: 0 }}>
              <PictureAsPdfIcon sx={{ mr: 1 }} fontSize="large" />
              <PageTitle>全部事項PDFアップロード</PageTitle>
            </Box>
            <Typography sx={{ ml: 5, mb: 3 }}>
              Zipファイルをアップロードしてください
            </Typography>
          </Box>
          <Box sx={{ my: 2, mx: 4 }}>
            <Button
              label={"実行する"}
              variant={ButtonVariantOption.Contained}
              size={SizingWrapperStyle.SMALL}
              onClick={() => {
                const results = [
                  validator.identifierNumber.validate(identifierNumber),
                  validator.identifierName.validate(identifierName),
                ];
                // resultsの全ての値がtrueならvalidation通過とみなす
                if (results.every((value) => value)) {
                  setOpenModalFlag(true);
                }
              }}
            />
          </Box>
        </Stack>
        <SectionPaper>
          <Grid sx={{ m: 2 }}>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={6}>
                <TextField
                  error={validator.identifierNumber.state.error}
                  label="識別番号"
                  helperText={"このファイルにわかりやすい番号を付けてください"}
                  required={true}
                  value={identifierNumber}
                  onKeyDown={preventNonNumericValueWithoutBackspaceWhenKeyDown}
                  onPaste={preventNonNumericValueWhenPaste}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIdentifierNumber(event.target.value);
                    validator.identifierNumber.validate(event.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={validator.identifierName.state.error}
                  label="識別名"
                  helperText={"このファイルにわかりやすい名前を付けてください"}
                  required={true}
                  value={identifierName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIdentifierName(event.target.value);
                    validator.identifierName.validate(event.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "2px dashed",
                borderRadius: "2px",
                borderColor: "black",
                mt: 3,
              }}
            >
              <Grid container spacing={2} sx={{ my: 5 }}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography sx={{ lineHeight: 2 }}>
                    アップロードボタンを押してファイルを選択してください
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FileUploadButton
                      variant={ButtonVariantOption.Contained}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        // ファイルが選択されているときのみ確認画面へボタンを有効にする
                        const files = event.target.files ?? [];
                        if (files.length > 0) {
                          setFileToUpload(files[0]);
                          // setCsvUploadConfirmButtonDisabled(false);
                        } else {
                          if (fileToUpload === null) {
                            // setCsvUploadConfirmButtonDisabled(true);
                          }
                        }
                      }}
                      accept={".pdf,.zip"}
                      label={"アップロード"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography textAlign={"center"}>
                    {fileToUpload ? `ファイル名:${fileToUpload?.name}` : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>

      {/* 確認のモーダル画面 */}
      <Dialog
        open={openModalFlag}
        onClose={() => {
          setOpenModalFlag(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"確認画面"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            【課金が発生します】全部事項解析を実行します
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            variant={ButtonVariantOption.Contained}
            onClick={handleFileUploadButtonClick}
            label={"実行する"}
            timeout={30000}
          />
          <Button
            variant={ButtonVariantOption.Outlined}
            onClick={() => {
              setOpenModalFlag(false);
            }}
            label={"戻る"}
          />
        </DialogActions>
      </Dialog>
    </PagePaper>
  );
};
