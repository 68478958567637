import { styled } from "@mui/material";
import {
  DataGrid as MuiDataGrid,
  jaJP,
  type DataGridProps,
} from "@mui/x-data-grid";

// 不要であったり、不変にしたり等で外から受け取らないプロパティ
type OmitProperties = "density" | "localeText";

interface IProps extends Omit<DataGridProps, OmitProperties> {}

const KaokuNumberSearchDataGrid: React.FC<IProps> = (props) => {
  return (
    <MuiDataGrid
      density="standard"
      localeText={{
        ...jaJP.components.MuiDataGrid.defaultProps.localeText,
      }}
      {...props}
    />
  );
};

const StyledDataGrid = styled(KaokuNumberSearchDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

export { StyledDataGrid };
