import { type GridSelectionModel } from "@mui/x-data-grid";
import {
  type AxiosInstance,
  type AxiosResponse,
  HttpStatusCode,
  isAxiosError,
} from "axios";
import { getAttachedFileNameFromHeader } from "@/utils/utils";
import fileDownload from "js-file-download";
import * as Sentry from "@sentry/react";

interface IRequests {
  ids: GridSelectionModel; // モニタリング詳細テーブル（RealEstateMonitoringDetail）のIDの配列
}

/**
 * モニタリング登録を解除するAPIを実行する。
 * @param {AxiosInstance} apiClient
 * @param {string} url
 * @param {IRequests} requests
 */
export const GetMonitoringAsFileAPI = async (
  apiClient: AxiosInstance,
  requests: IRequests
): Promise<void> => {
  const urlWithParams =
    "/monitoring/file/?" +
    new URLSearchParams(
      requests.ids.map((id) => {
        return ["id", String(id)];
      }) ?? []
    ).toString();

  try {
    const response = await apiClient.get(urlWithParams, {
      responseType: "blob",
    });
    const filename = getAttachedFileNameFromHeader(
      response,
      "real-estate-monitoring-export-data.csv"
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    fileDownload(response.data, filename);
  } catch (error) {
    if (
      isAxiosError<AxiosResponse>(error) &&
      (error.response?.status ?? 500) >= HttpStatusCode.InternalServerError
    ) {
      Sentry.captureException(error);
    }
    throw error;
  }
};
