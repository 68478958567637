import React, { useCallback, useEffect } from "react";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { type KaokuNumberSearchChiban } from "@/types/kaokuNumber";
import { type PropertySelectionRow } from "../types";
import { Button, ButtonVariantOption } from "@/components/Button";
import { HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { SizingWrapperStyle } from "@/components/Wrapper";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useKaokuNumber } from "@/hooks/useKaokuNumber";
import { ConfirmDialogChiban } from "@/components/KaokuNumberSearch/ConfirmDialogChiban";
import { ConfirmDialogKaokuNumber } from "@/components/KaokuNumberSearch/ConfirmDialogKaokuNumber";
import { ViewAreaChiban } from "@/components/KaokuNumberSearch/ViewAreaChiban";
import { ViewAreaKaokuNumber } from "@/components/KaokuNumberSearch/ViewAreaKaokuNumber";

interface Props {
  chibanList: KaokuNumberSearchChiban[];
  setKaokuNumberSearchModalIsOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  appendPropertySelectionRows: (rows: PropertySelectionRow[]) => void;
}

/**
 * 地図検索の家屋番号検索モーダル
 * @param {Props} props
 * @returns {React.FC<Props>}
 */
export const KaokuNumberSearchModal: React.FC<Props> = (props) => {
  const {
    chibanList,
    setKaokuNumberSearchModalIsOpen,
    appendPropertySelectionRows,
  } = props;
  const {
    convertResultToKaokuNumbers,
    convertToChibanRows,
    convertToKaokuNumberRows,
    convertToPropertySelectionRows,
    createKaokuNumberSearchRequest,
    getKaokuNumberMaxRequestCount,
    getKaokuNumberSearchRequest,
    kaokuNumberRows,
    setKaokuNumberRows,
    loading,
    setLoading,
    selectedChibanRowIds,
    setSelectedChibanRowIds,
    selectedKaokuNumberRowIds,
    setSelectedKaokuNumberRowIds,
    openSearchModalFlag,
    setOpenSearchModalFlag,
    openAddRowsModalFlag,
    setOpenAddRowsModalFlag,
    pollingRequestId,
    setPollingRequestId,
  } = useKaokuNumber(chibanList);

  const maxRequestCount = getKaokuNumberMaxRequestCount();

  const handleChibanRowSelectionChange = useCallback(
    (newSelectionModel: GridSelectionModel) => {
      setSelectedChibanRowIds(newSelectionModel);
    },
    []
  );

  const handleKaokuNumberRowSelectionChange = useCallback(
    (newSelectionModel: GridSelectionModel) => {
      setSelectedKaokuNumberRowIds(newSelectionModel);
    },
    []
  );

  useEffect(() => {
    if (!pollingRequestId) {
      return;
    }

    // 3秒ごとにポーリング
    const intervalId = setInterval(() => {
      void fetchKaokuNumberSearchRequest();
    }, 3000);

    // 15分でタイムアウト
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      setPollingRequestId(null);
    }, 1000 * 60 * 15);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [pollingRequestId]);

  const handleClickSubmitSearchButton = (cancelLoading: () => void): void => {
    setLoading(true);

    // リクエスト作成APIを呼び出す
    const selectedChibanList = convertToChibanRows()
      .filter((row) => {
        return selectedChibanRowIds.includes(row.id);
      })
      .map((row) => {
        return {
          prefectureName: row.prefectureName,
          location: row.location,
          chiban: row.chiban,
        };
      });

    (async () => {
      try {
        const response = await createKaokuNumberSearchRequest(
          selectedChibanList
        );

        if (response) {
          if (response.status === HttpStatusCode.Accepted && response.id) {
            setPollingRequestId(response.id);
            setOpenSearchModalFlag(false);
            return;
          } else if (response.status === HttpStatusCode.BadRequest) {
            setLoading(false);
            if (
              response.error &&
              response.error === "登記情報提供サービスの営業時間外です"
            ) {
              toast.error("登記情報提供サービスの営業時間外です");
            } else {
              toast.error(`件数は${maxRequestCount}件以下にしてください。`);
            }
          }
        }
      } catch (error) {
        setLoading(false);
        if (
          isAxiosError(error) &&
          error.response?.status !== HttpStatusCode.Unauthorized
        ) {
          Sentry.captureException(error);
        }
      } finally {
        cancelLoading();
      }
    })();
  };

  const fetchKaokuNumberSearchRequest = async (): Promise<void> => {
    if (!pollingRequestId) {
      return;
    }

    const response = await getKaokuNumberSearchRequest(pollingRequestId);
    if (response) {
      if (response.status === "COMPLETED") {
        const rows = convertToKaokuNumberRows(
          convertResultToKaokuNumbers(response)
        );
        setKaokuNumberRows(rows);
        setSelectedKaokuNumberRowIds(
          rows.filter((row) => row.selected).map((row) => row.id)
        );

        setPollingRequestId(null);
        setLoading(false);

        if (rows.length === 0) {
          toast.info("検索結果は0件です");
        }
      }
    }
  };

  const handleClickSubmitAddRowsButton = (cancelLoading: () => void): void => {
    const rows = convertToPropertySelectionRows();

    appendPropertySelectionRows(rows);
    setOpenAddRowsModalFlag(false);
    setKaokuNumberSearchModalIsOpen(false);
  };

  const handleClickCloseModalButton = (): void => {
    if (loading) {
      const confirmClose = window.confirm(
        "検索中です。画面を閉じるとキャンセルされます。よろしいですか？"
      );
      if (!confirmClose) {
        return;
      }
    }
    setKaokuNumberSearchModalIsOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          outline: "none",
        }}
      >
        <Button
          label="閉じる"
          variant={ButtonVariantOption.Outlined}
          onClick={handleClickCloseModalButton}
          size={SizingWrapperStyle.SMALL}
          startIcon={<CloseIcon />}
        ></Button>
      </Box>
      <PagePaper>
        {/* 地番領域 */}
        <Box>
          <SectionPaper>
            <ViewAreaChiban
              chibanCount={selectedChibanRowIds.length}
              handleRowSelectionChange={handleChibanRowSelectionChange}
              loading={loading}
              maxRequestCount={maxRequestCount}
              rows={convertToChibanRows()}
              selectedRowIds={selectedChibanRowIds}
              setOpenSearchModalFlag={setOpenSearchModalFlag}
            />
          </SectionPaper>
        </Box>

        {/* 家屋番号領域 */}
        <Box marginTop={2}>
          <SectionPaper>
            <ViewAreaKaokuNumber
              disabled={loading}
              handleRowSelectionChange={handleKaokuNumberRowSelectionChange}
              label={"リストに追加"}
              loading={loading}
              rows={kaokuNumberRows}
              selectedRowIds={selectedKaokuNumberRowIds}
              setOpenAddRowsModalFlag={setOpenAddRowsModalFlag}
            />
          </SectionPaper>
        </Box>
      </PagePaper>

      {/* 地番領域のボタン押下時に表示されるダイアログ */}
      <ConfirmDialogChiban
        chibanCount={selectedChibanRowIds.length}
        handleClickSubmitSearchButton={handleClickSubmitSearchButton}
        loading={loading}
        openSearchModalFlag={openSearchModalFlag}
        setOpenSearchModalFlag={setOpenSearchModalFlag}
      />

      {/* 家屋番号領域のボタン押下時に表示されるモーダル */}
      <ConfirmDialogKaokuNumber
        dialogContentText={`${selectedKaokuNumberRowIds.length}件をリストに追加します。`}
        handleClickSubmitAddRowsButton={handleClickSubmitAddRowsButton}
        openAddRowsModalFlag={openAddRowsModalFlag}
        setOpenAddRowsModalFlag={setOpenAddRowsModalFlag}
      />
    </>
  );
};
