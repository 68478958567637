import { toast } from "react-toastify";
import { useApiClient } from "@/hooks/useApiClient";
import { Box, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetMonitoringDetailReceptionBookAPI } from "../api";
import {
  type MonitoringSummaryRow,
  type MonitoringDetailRow,
  type MonitoringDetailReceptionBookRow,
  type GetMonitoringReceptionBooksAPIResponse,
} from "../types";
import { PagePaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { MonitoringDetailReceptionBookDataGrid } from "../components/MonitoringDetailReceptionBookDataGrid";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import { HttpStatusCode, isAxiosError } from "axios";
import { GetPrefecturesAPI } from "@/api/GetPrefecturesAPI";
import { type IPrefecture } from "@/types/prefectures";
import { getDisplayReceptionReason } from "@/features/monitoring//utils/getDisplayReceptionReason";

/**
 * モニタリング詳細受付帳結果画面
 */
export const MonitoringDetailReceptionBook: React.FC = () => {
  const { id } = useParams();
  const { apiClient } = useApiClient();
  const [monitoringSummary, setMonitoringSummary] =
    useState<MonitoringSummaryRow | null>(null);
  const [monitoringDetail, setMonitoringDetail] =
    useState<MonitoringDetailRow | null>(null);
  const [rows, setRows] = useState<MonitoringDetailReceptionBookRow[]>([]); // 受付帳結果用State
  const [prefectures, setPrefectures] = useState<IPrefecture[]>([]);
  const [chibanKaokuNumberLabel, setChibanKaokuNumberLabel] =
    useState<string>("地番または家屋番号");

  const { data, isLoading, error } = GetMonitoringDetailReceptionBookAPI(
    apiClient,
    Number(id)
  );

  useEffect(() => {
    (async () => {
      // 都道府県リストをAPIから取得する。
      const prefectures = await GetPrefecturesAPI(apiClient);
      setPrefectures(prefectures);
    })();
  }, []);

  useEffect(() => {
    (() => {
      if (
        isAxiosError<GetMonitoringReceptionBooksAPIResponse>(error) &&
        (error.response?.status === HttpStatusCode.BadRequest ||
          error.response?.status === HttpStatusCode.NotFound)
      ) {
        toast.error("指定のデータが存在しません。");
        return;
      }
      if (
        isAxiosError<GetMonitoringReceptionBooksAPIResponse>(error) &&
        (error.response?.status ?? 500) >= HttpStatusCode.InternalServerError
      ) {
        toast.error("データの取得に失敗しました。再度お試しください。");
        return;
      }
      setMonitoringSummary(
        data
          ? {
              id: data.summary.id,
              count: data.summary.count,
              name: data.summary.name,
              receptionReasons: getDisplayReceptionReason(
                data.summary.receptionReasons
              ),
              realEstateOwnerAnalyzeRequestId:
                data.summary.realEstateOwnerAnalyzeRequestId,
              pictureRequestId: data.summary.pictureRequestId,
              monthlyUpdatable: data.summary.monthlyUpdatable
                ? "する"
                : "しない",
              legalAffairsBureauRequestDateStart:
                data.summary.legalAffairsBureauRequestDateStart,
              legalAffairsBureauRequestDateEnd:
                data.summary.legalAffairsBureauRequestDateEnd,
              createdAt: data.summary.createdAt,
            }
          : null
      );
      setMonitoringDetail(
        data
          ? {
              id: data.detail.id,
              prefectureName:
                prefectures.find(
                  (prefecture) => prefecture.id === data.detail.prefectureId
                )?.name ?? "",
              realEstateType: data.detail.realEstateType,
              location: data.detail.location,
              chibanKaokuNumber: data.detail.chibanKaokuNumber,
              registrationDate: data.detail.registrationDate,
              remarks: data.detail.remarks,
            }
          : null
      );
      setChibanKaokuNumberLabel(
        data
          ? data.detail.realEstateType === "土地"
            ? "地番"
            : "家屋番号"
          : "地番または家屋番号"
      );
      setRows(
        data?.receptionBooks.map((receptionBook) => ({
          id: receptionBook.id,
          legalAffairsBureauRequestDate:
            receptionBook.legalAffairsBureauRequestDate,
          receptionReason: receptionBook.receptionReason?.receptionReasonFormal,
          soto: receptionBook.outside,
        })) ?? []
      );
    })();
  }, [data, error, prefectures]);

  return (
    <PagePaper>
      {/* ページタイトル */}
      <Box sx={{ display: "flex", mb: 3 }}>
        <MonitorHeartIcon sx={{ mr: 1 }} fontSize="large" />
        <PageTitle>不動産登記モニタリング詳細受付帳結果</PageTitle>
      </Box>

      {/* モニタリング詳細の情報 */}
      <Stack direction={"column"} spacing={1} sx={{ mt: 3 }}>
        <Stack direction={"row"} spacing={1}>
          <Typography>不動産種別：</Typography>
          <Typography>{monitoringDetail?.realEstateType}</Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography>所在：</Typography>
          <Typography>{monitoringDetail?.location}</Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography>{chibanKaokuNumberLabel}：</Typography>
          <Typography>{monitoringDetail?.chibanKaokuNumber}</Typography>
        </Stack>
      </Stack>

      {/* 結果 */}
      <Box sx={{ mt: 3 }}>
        {isLoading || data == null ? (
          <Stack alignItems="center">
            <CircularProgress sx={{ my: "20px" }} />
          </Stack>
        ) : (
          <MonitoringDetailReceptionBookDataGrid rows={rows} />
        )}
      </Box>

      {/* 戻るリンク */}
      <Box sx={{ mt: 3 }}>
        <Link
          href={
            monitoringSummary != null
              ? `/monitoring/${monitoringSummary.id}`
              : "/monitoring"
          }
          sx={{ display: "inline-block" }}
        >
          <Box sx={{ display: "flex" }}>
            <KeyboardArrowLeft />
            <Typography>
              {monitoringSummary != null
                ? "不動産モニタリング詳細に戻る"
                : "不動産モニタリング一覧に戻る"}
            </Typography>
          </Box>
        </Link>
      </Box>
    </PagePaper>
  );
};
