import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { format } from "date-fns";
import { Box, Grid, Link, Typography } from "@mui/material";
import {
  Button,
  ButtonColorOption,
  ButtonVariantOption,
  LinkButton,
  SubmitButton,
} from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { KeyboardArrowLeft } from "@mui/icons-material";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import React, { useEffect, useRef, useState } from "react";
import { useFeatureFlags } from "@/configs/featureFlag";
import { DateRangePicker } from "@/components/DatePicker";
import {
  getSearchMaxDate,
  getSearchMinDate,
} from "@/features/realEstateReceptionBookFeed/utils/dateTime";
import { MultiSelect } from "@/components/Select";
import { useReceptionReasonOptions } from "@/features/realEstateReceptionBookFeed/hooks/useReceptionReasonOptions";
import { Divider } from "@/components/Divider";
import { MonitoringFeedGrid } from "@/features/monitoring/components/MonitoringFeedGrid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReceptionBookImportStatus } from "@/features/realEstateReceptionBookFeed/components/ReceptionBookImportStatus";
import { useReceptionBookImportStatuses } from "@/features/realEstateReceptionBookFeed/hooks/useReceptionBookImportStatuses";
import { GetMonitoringAsFileAPI } from "@/features/monitoring/api/GetMonitoringAsFileAPI";
import { useApiClient } from "@/hooks/useApiClient";
import { type AxiosResponse, HttpStatusCode, isAxiosError } from "axios";
import { CustomModal } from "@/components/Modal";
import {
  type UpdateParameterEntity,
  type GetMonitoringSummariesAPIResponse,
} from "../types";
import { PutMonitoringAPI } from "@/features/monitoring/api/PutMonitoringAPI";

export const Monitoring: React.FC = () => {
  const {
    mypageTop,
    realEstateRegistrationMonitoring,
    realEstateRegistrationMonitoringInformation,
  } = useFeatureFlags();

  // FeedGridに更新を指示するためのref
  const ref = useRef<{ reload: () => void }>(null);

  const startDate = new Date();
  const minDate = getSearchMinDate();
  const maxDate = getSearchMaxDate();

  const { receptionReasonOptions } = useReceptionReasonOptions({ short: true });

  const [createdDateStart, setCreatedDateStart] = useState<Date | null>(null);
  const [createdDateEnd, setCreatedDateEnd] = useState<Date | null>(null);

  const [receptionReasons, setReceptionReasons] = useState<string[]>([]);

  const [apiResponseData, setApiResponseData] = useState<
    GetMonitoringSummariesAPIResponse | undefined
  >(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>([]);

  const [disableFileDownloadButton, setDisableFileDownloadButton] =
    useState<boolean>(false);

  const [
    disableBulkMonitoringCancelButton,
    setDisableBulkMonitoringCancelButton,
  ] = useState<boolean>(false);

  // モーダル表示用State
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // 確認ボタン押下後のキャンセルボタン無効化State
  const [isPushedConfirmButton, setIsPushedConfirmButton] =
    useState<boolean>(false);

  const importStatuses = useReceptionBookImportStatuses();

  const { apiClient } = useApiClient();

  // 一括解除ボタン押下時の処理
  const handleSelectedRowCancel = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cancelLoading: () => void
  ): void => {
    setIsPushedConfirmButton(true);
    if (selectedRowIds.length !== 0 && apiResponseData) {
      const updateParameters = apiResponseData.summaries
        .filter((summary): boolean => {
          return (
            selectedRowIds.includes(summary.id) &&
            (summary?.monthlyUpdatable ?? false)
          );
        })
        .map((summary): UpdateParameterEntity => {
          return {
            id: summary.id,
            name: summary.name,
            receptionReasonIds: summary.receptionReasons.map((reason) =>
              String(reason.id)
            ),
            realEstateOwnerAnalyzeRequestId:
              summary.realEstateOwnerAnalyzeRequestId,
            pictureRequestId: summary.pictureRequestId,
            monthlyUpdatable: false,
            legalAffairsBureauRequestDateStart:
              summary.legalAffairsBureauRequestDateStart,
            legalAffairsBureauRequestDateEnd:
              summary.legalAffairsBureauRequestDateEnd,
          };
        });

      PutMonitoringAPI(apiClient, { updateParameters })
        .then(() => {
          toast.success("モニタリング登録を解除しました。");
        })
        .catch((error) => {
          if (
            isAxiosError<AxiosResponse>(error) &&
            (error.response?.status === HttpStatusCode.BadRequest ||
              error.response?.status === HttpStatusCode.NotFound)
          ) {
            toast.error("指定のデータが存在しません。");
            return;
          }
          if (
            isAxiosError<AxiosResponse>(error) &&
            (error.response?.status ?? 500) >=
              HttpStatusCode.InternalServerError
          ) {
            toast.error(
              "モニタリング登録の解除に失敗しました。再度お試しください。"
            );
          }
        })
        .finally(() => {
          setSelectedRowIds([]);
          setIsOpen(false);
          setIsPushedConfirmButton(false);
          cancelLoading();
          ref.current?.reload();
        });
    }
  };

  // 一括ダウンロード処理
  const handleCsvDownloadButtonClick = (): void => {
    if (selectedRowIds.length !== 0) {
      GetMonitoringAsFileAPI(apiClient, { ids: selectedRowIds }).catch(
        (error) => {
          if (
            isAxiosError<AxiosResponse>(error) &&
            error.response?.status === HttpStatusCode.BadRequest
          ) {
            toast.error("CSVファイルのダウンロードに失敗しました。");
            return;
          }
          if (
            isAxiosError<AxiosResponse>(error) &&
            (error.response?.status ?? 500) >=
              HttpStatusCode.InternalServerError
          ) {
            Sentry.captureException(error);
            toast.error("CSVファイルのダウンロードに失敗しました");
          }
        }
      );
    }
  };

  useEffect(() => {
    if (selectedRowIds.length === 0) {
      setDisableFileDownloadButton(true);
      setDisableBulkMonitoringCancelButton(true);
      return;
    }

    if (apiResponseData == null) {
      setDisableFileDownloadButton(true);
      setDisableBulkMonitoringCancelButton(true);
      return;
    }

    setDisableFileDownloadButton(false);

    for (const id of selectedRowIds) {
      const summary = apiResponseData.summaries.find((summary) => {
        return summary.id === id;
      });
      if (summary?.monthlyUpdatable) {
        setDisableBulkMonitoringCancelButton(false);
        return;
      }
    }

    setDisableBulkMonitoringCancelButton(true);
  }, [selectedRowIds.length]);

  if (!realEstateRegistrationMonitoring) return null;

  return (
    <PagePaper>
      {realEstateRegistrationMonitoringInformation ? (
        <Box sx={{ mb: 3 }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">お知らせ</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ReceptionBookImportStatus importStatuses={importStatuses} />
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : null}
      <form>
        {/* ページタイトル */}
        <Box sx={{ display: "flex", mb: 3 }}>
          <MonitorHeartIcon sx={{ mr: 1 }} fontSize="large" />
          <PageTitle>不動産登記モニタリング</PageTitle>
        </Box>

        <SectionPaper>
          {/* モニタリング概要検索条件入力欄 */}
          <Grid container columnSpacing={2} rowSpacing={1} py={3} px={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  rowGap: 2,
                }}
              >
                <DateRangePicker
                  startDateLabel="登録日 開始"
                  startDate={createdDateStart}
                  onChangeStartDate={setCreatedDateStart}
                  endDateLabel="登録日 終了"
                  endDate={createdDateEnd}
                  onChangeEndDate={setCreatedDateEnd}
                  minDate={minDate}
                  maxDate={maxDate}
                  defaultCalendarMonth={startDate}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <MultiSelect
                  label="登記原因"
                  value={receptionReasons}
                  onChange={setReceptionReasons}
                  options={receptionReasonOptions}
                  size={SizingWrapperStyle.MEDIUM}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
                  flexDirection: { xs: "column", sm: "row" },
                  rowGap: 1,
                  columnGap: 2,
                }}
              >
                <LinkButton
                  variant={ButtonVariantOption.Contained}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdYazgZcqCbsObM9kcNVpHCgkQjJ2XtYWSx_L-Qw4OTJtCkSA/viewform"
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                  wrapperSize={SizingWrapperStyle.INHERIT}
                >
                  データクレンジング
                  <br />
                  依頼
                </LinkButton>
                <LinkButton
                  variant={ButtonVariantOption.Contained}
                  href="/monitoring/upload"
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                  wrapperSize={SizingWrapperStyle.INHERIT}
                >
                  モニタリングデータ
                  <br />
                  アップロード
                </LinkButton>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* モニタリング概要 一覧 */}
            <Grid item xs={12}>
              <MonitoringFeedGrid
                ref={ref}
                filterCondition={{
                  createdDateStart: createdDateStart
                    ? format(createdDateStart, "yyyy-MM-dd")
                    : null,
                  createdDateEnd: createdDateEnd
                    ? format(createdDateEnd, "yyyy-MM-dd")
                    : null,
                  receptionReasonId: receptionReasons.map((reason) =>
                    Number(reason)
                  ),
                }}
                setApiResponseData={setApiResponseData}
                setSelectedRowIds={setSelectedRowIds}
                selectedRowIds={selectedRowIds}
              />
            </Grid>

            {/* モニタリング概要 一括操作 */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-start" },
                  flexDirection: { xs: "column", sm: "row" },
                  rowGap: 1,
                  columnGap: 2,
                }}
              >
                <Button
                  label="一括ダウンロード"
                  onClick={handleCsvDownloadButtonClick}
                  variant={ButtonVariantOption.Contained}
                  size={SizingWrapperStyle.MEDIUM}
                  disabled={disableFileDownloadButton}
                />
                <Button
                  label="モニタリング登録を解除する"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  variant={ButtonVariantOption.Contained}
                  size={SizingWrapperStyle.MEDIUM}
                  disabled={disableBulkMonitoringCancelButton}
                  color={ButtonColorOption.Warning}
                />
              </Box>
            </Grid>
          </Grid>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ pt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
      {/* 最終確認モーダル */}
      <CustomModal
        isOpen={isOpen}
        handleClose={(
          event: Record<string, unknown>,
          reason: "backdropClick" | "escapeKeyDown"
        ): void => {
          if (
            reason &&
            (reason === "backdropClick" || reason === "escapeKeyDown")
          ) {
            return;
          }
          setIsOpen(false);
        }}
        ariaLabelledby="confirm-modal"
        ariaDescribedby="confirm-modal-description"
      >
        <PageTitle>確認</PageTitle>
        <Box>
          <Typography>
            実行すると元に戻すことはできません。よろしいですか？
          </Typography>

          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <SubmitButton
              label={"はい"}
              timeout={120_000} // ms
              onClick={handleSelectedRowCancel}
              variant={ButtonVariantOption.Contained}
            />
            <Button
              label="いいえ"
              disabled={isPushedConfirmButton}
              onClick={() => {
                setIsOpen(false);
              }}
              variant={ButtonVariantOption.Outlined}
            />
          </Box>
        </Box>
      </CustomModal>
    </PagePaper>
  );
};
