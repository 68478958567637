import { type AxiosInstance, type AxiosError, HttpStatusCode } from "axios";
import useSWR from "swr";
import type { KeyedMutator } from "swr";
import type {
  GetMonitoringSummariesAPIRequest,
  GetMonitoringSummariesAPIResponse,
} from "@/features/monitoring/types";
import * as Sentry from "@sentry/react";

interface IResponse {
  data: GetMonitoringSummariesAPIResponse | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<GetMonitoringSummariesAPIResponse>;
  error: AxiosError | undefined;
}

/**
 * モニタリング詳細画面に表示するデータを取得する。
 * @param {AxiosInstance} apiClient
 * @param {GetMonitoringSummariesAPIRequest} requests
 * @returns {IResponse}
 */
export const GetMonitoringListAPI = (
  apiClient: AxiosInstance,
  requests: GetMonitoringSummariesAPIRequest | undefined
): IResponse => {
  const fetcher = async (
    url: string,
    params: GetMonitoringSummariesAPIRequest
  ): Promise<GetMonitoringSummariesAPIResponse> => {
    // NOTE: APIのURLパラメータの配列の表現形式に合わせる
    const urlWithParams =
      url +
      "?" +
      new URLSearchParams(
        [
          ["created_date_end", params.createdDateEnd ?? ""],
          ["created_date_start", params.createdDateStart ?? ""],
        ].concat(
          params.receptionReasonId?.map((id) => {
            return ["reception_reason_id", String(id)];
          }) ?? []
        )
      ).toString();

    const { data } = await apiClient.get<GetMonitoringSummariesAPIResponse>(
      urlWithParams
    );
    return data;
  };

  const { data, isLoading, mutate, error } = useSWR<
    GetMonitoringSummariesAPIResponse,
    AxiosError
  >(
    ["/monitoring/", requests],
    async ([url, params]: [string, GetMonitoringSummariesAPIRequest]) =>
      await fetcher(url, params),
    {
      // NOTE: バックエンドの120秒制限に合わせる
      errorRetryInterval: 120_000,
      revalidateOnFocus: false,
    }
  );

  if (
    error &&
    (error.response?.status ?? 500) >= HttpStatusCode.InternalServerError
  ) {
    Sentry.captureException(error);
    return { data: undefined, isLoading, mutate, error };
  }

  return { data, isLoading, mutate, error };
};
