import { Box, Button, Grid, Link, Stack, Typography } from "@mui/material";

import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { FeedGrid } from "../components/OwnerInfoListFeedGrid";
import { KeyboardArrowLeft } from "@mui/icons-material";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { type HandOffMessage } from "@/types/handOffMessage";
import { AlertBar } from "@/features/acquireMultipleBooks/components/AlertBar";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useFeatureFlags } from "@/configs/featureFlag";
import { ButtonVariantOption, LinkButton } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { ExecuteModal } from "@/features/monitoring/components/ExecuteModal";
import { type GridRowId } from "@mui/x-data-grid";

export const OwnerInfoList: React.FC = () => {
  // 遷移元の画面からデータを引き継ぐ
  const location = useLocation();

  // FeedGridに更新を指示するためのref
  const ref = useRef<{ reload: () => void }>(null);

  const {
    ownerInfoList: isOwnerInfoFeatureEnabled,
    mypageTop,
    realEstateRegistrationMonitoring,
  } = useFeatureFlags();

  // メッセージを表示するためのState、 ""の場合表示しない
  const [alertMessage, setAlertMessage] = useState("");

  const [
    openMonitoringExecuteConfirmDialog,
    setOpenMonitoringExecuteConfirmDialog,
  ] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<GridRowId>(""); // チェックボックスやAction選択の管理用State

  useEffect(() => {
    // 前の画面から引き継いだメッセージを確認する
    const locationState = location.state as Record<string, unknown>;
    if (locationState?.handOffMessage) {
      const message = locationState.handOffMessage as HandOffMessage;
      if (message.command === "OWNER_INFO_SHOW_ACCEPT_MESSAGE") {
        setAlertMessage(
          "所有者事項解析を受け付けました。最新の受付状況を確認するには更新ボタンを押してください"
        );
      }
    }
  }, []);

  return (
    <PagePaper>
      {alertMessage !== "" ? (
        <AlertBar
          severity="info"
          message={alertMessage}
          onClose={() => {
            setAlertMessage("");
          }}
        />
      ) : (
        ""
      )}
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <LibraryBooksIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>所有者事項解析一覧</PageTitle>
          </Box>
          <Box sx={{ ml: 3 }}>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={() => {
                ref.current?.reload();
              }}
            >
              更新
            </Button>
          </Box>
        </Box>

        {isOwnerInfoFeatureEnabled ? (
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs>
              <Stack my={2} direction="row" justifyContent="end" spacing={1}>
                <LinkButton
                  size="large"
                  variant={ButtonVariantOption.Contained}
                  wrapperSize={SizingWrapperStyle.INHERIT}
                  href="/ownerinfo/upload"
                  sx={{ textAlign: "center" }}
                >
                  所有者事項
                  <br />
                  PDFアップロード
                </LinkButton>
              </Stack>
            </Grid>
          </Grid>
        ) : null}

        <SectionPaper>
          <FeedGrid
            ref={ref}
            setOpenMonitoringExecuteConfirmDialog={
              setOpenMonitoringExecuteConfirmDialog
            }
            setSelectedId={setSelectedId}
          />
          <Box sx={{ mb: 6 }} />
          <Stack
            direction={"row"}
            spacing={4}
            justifyContent={"center"}
            mb={2}
          ></Stack>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
      {realEstateRegistrationMonitoring ? (
        <ExecuteModal
          open={openMonitoringExecuteConfirmDialog}
          setOpen={setOpenMonitoringExecuteConfirmDialog}
          realEstateOwnerAnalyzeRequestId={Number(selectedId)}
        />
      ) : null}
    </PagePaper>
  );
};
