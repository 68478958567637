import { type AxiosInstance, type AxiosError, HttpStatusCode } from "axios";
import useSWR from "swr";
import type { KeyedMutator } from "swr";
import type { GetMonitoringDetailsAPIResponse } from "@/features/monitoring/types";
import * as Sentry from "@sentry/react";

interface IResponse {
  data: GetMonitoringDetailsAPIResponse | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<GetMonitoringDetailsAPIResponse>;
  error: AxiosError | undefined;
}

export const GetMonitoringDetailAPI = (
  apiClient: AxiosInstance,
  id: number
): IResponse => {
  const fetcher = async (
    url: string
  ): Promise<GetMonitoringDetailsAPIResponse> => {
    const { data } = await apiClient.get<GetMonitoringDetailsAPIResponse>(url);
    return data;
  };

  const { data, isLoading, mutate, error } = useSWR<
    GetMonitoringDetailsAPIResponse,
    AxiosError
  >([`/monitoring/${id}`], async ([url]: [string]) => await fetcher(url), {
    // NOTE: バックエンドの120秒制限に合わせる
    errorRetryInterval: 120_000,
    revalidateOnFocus: false,
  });

  if (
    error &&
    (error.response?.status ?? 500) >= HttpStatusCode.InternalServerError
  ) {
    Sentry.captureException(error);
  }

  return { data, isLoading, mutate, error };
};
