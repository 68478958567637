import { styled } from "@mui/material/styles";
import { AcquireBookDataGrid } from "../../../components/DataGrid";
import {
  type GridSelectionModel,
  type GridColDef,
  type GridRenderCellParams,
  type GridRowId,
} from "@mui/x-data-grid";
import { useApiClient } from "../../../hooks/useApiClient";
import { type AxiosInstance } from "axios";
import useSWR from "swr";
import { ButtonVariantOption, LinkButton } from "../../../components/Button";
import { SizingWrapperStyle } from "../../../components/Wrapper";
import { Button, Stack } from "@mui/material";
import { useFeatureFlags } from "@/configs/featureFlag";

interface IProps {
  setSelectedId: React.Dispatch<React.SetStateAction<GridRowId>>;
  setOpenMonitoringExecuteConfirmDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

interface AcquireBookStatusDataGridRow {
  id: number;
  requestedAt: Date;
  requestPictureCount: number;
  status: string;
  action?: boolean;
}

interface PictureRequestAPIRequestStatus {
  id: number;
  task_id: string;
  status: string;
  name: string;
  requestPictureCount: number;
  requestedAt: Date;
  finishedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

interface PictureRequestAPIResponse {
  count: number;
  list: PictureRequestAPIRequestStatus[];
}

const StyledDataGrid = styled(AcquireBookDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (
  realEstateRegistrationMonitoring: boolean,
  setSelectedId: React.Dispatch<React.SetStateAction<GridRowId>>,
  setAcquireBookOpen: React.Dispatch<React.SetStateAction<boolean>>
): GridColDef[] => {
  const actionColumnMinWidth = realEstateRegistrationMonitoring ? 400 : 200;

  return [
    {
      field: "id",
      headerName: "No",
      width: 90,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "name",
      headerName: "識別名",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "requestedAt",
      headerName: "登記 / 図面依頼日",
      width: 220,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value === undefined) return "";
        const date = new Date(value);
        // 秒の値が1桁の場合は先頭に0を挿入して2桁にする
        const minutes =
          date.getMinutes() <= 9
            ? `0${date.getMinutes()}`
            : `${date.getMinutes()}`;
        return `${date.getFullYear()}/${
          date.getMonth() + 1
        }/${date.getDate()} ${date.getHours()}:${minutes}`;
      },
    },
    {
      field: "requestPictureCount",
      headerName: "取得数（リクエスト数）",
      width: 180,
      sortable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "status",
      headerName: "取得状況",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value === undefined) return "";
        switch (value) {
          case "REQUESTING":
            return "依頼中";
          case "SUCCESS":
            return "成功";
          case "SOME_FAILED":
            return "失敗あり";
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: actionColumnMinWidth,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({
        id,
        row,
      }: GridRenderCellParams<string, AcquireBookStatusDataGridRow>) => {
        return (
          <Stack direction="row" spacing={2}>
            <LinkButton
              variant={ButtonVariantOption.Contained}
              href={`/acquirebookstatus/${id}/`}
              wrapperSize={SizingWrapperStyle.SMALL}
            >
              詳細
            </LinkButton>
            {realEstateRegistrationMonitoring ? (
              <Button
                variant={ButtonVariantOption.Contained}
                onClick={() => {
                  setSelectedId(id);
                  setAcquireBookOpen(true);
                }}
                disabled={row.status === "REQUESTING"}
              >
                モニタリング
              </Button>
            ) : null}
          </Stack>
        );
      },
    },
  ];
};

const usePictureRequest = (
  apiClient: AxiosInstance
): AcquireBookStatusDataGridRow[] => {
  const fetcher = async (url: string): Promise<PictureRequestAPIResponse> => {
    const { data } = await apiClient.get<PictureRequestAPIResponse>(url);
    return data;
  };

  const { data } = useSWR<PictureRequestAPIResponse>(
    "/picture-request/",
    fetcher,
    { revalidateOnFocus: true }
  );

  if (data === undefined) return [];
  return data.list;
};

export const FeedGrid: React.FC<IProps> = (props: IProps) => {
  const { setOpenMonitoringExecuteConfirmDialog, setSelectedId } = props;
  const { apiClient } = useApiClient();
  const { realEstateRegistrationMonitoring } = useFeatureFlags();
  const colDef = makeGridColDef(
    realEstateRegistrationMonitoring as boolean,
    setSelectedId,
    setOpenMonitoringExecuteConfirmDialog
  );

  const rows = usePictureRequest(apiClient);

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={rows}
      columns={colDef}
      sortModel={[
        {
          field: "requestedAt",
          sort: "desc",
        },
      ]}
    />
  );
};
