import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Button, ButtonVariantOption } from "../Button";
import { StyledDataGridKaokuNumber } from "./StyledDataGridKaokuNumber";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { type KaokuNumberRow } from "@/types/kaokuNumber";

interface Props {
  disabled: boolean;
  handleRowSelectionChange: (newSelectionModel: GridSelectionModel) => void;
  label: string;
  loading: boolean;
  rows: KaokuNumberRow[];
  selectedRowIds: GridSelectionModel;
  setOpenAddRowsModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * 家屋番号の表示領域
 * @param {Props} props
 * @returns {React.FC<Props>}
 */
export const ViewAreaKaokuNumber: React.FC<Props> = (props) => {
  const {
    disabled,
    handleRowSelectionChange,
    label,
    loading,
    rows,
    selectedRowIds,
    setOpenAddRowsModalFlag,
  } = props;
  return (
    <>
      <Typography sx={{ pl: 2, pt: 1, fontWeight: "bold" }}>
        家屋番号
      </Typography>
      <Box marginY={1} marginX={2} sx={{ height: 300 }}>
        <StyledDataGridKaokuNumber
          handleRowSelectionChange={handleRowSelectionChange}
          loading={loading}
          rows={rows}
          selectedRowIds={selectedRowIds}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={1}
        marginTop={1}
        marginBottom={2}
        marginRight={2}
      >
        <Button
          label={label}
          variant={ButtonVariantOption.Contained}
          onClick={() => {
            setOpenAddRowsModalFlag(true);
          }}
          disabled={disabled}
        />
      </Stack>
    </>
  );
};
