import { numberFormat } from "@/utils/utils";

const CHIBAN_PATTERN = [
  "甲",
  "乙",
  "丙",
  "丁",
  "戊",
  "子",
  "丑",
  "寅",
  "卯",
  "辰",
  "巳",
  "午",
  "未",
  "申",
  "酉",
  "戌",
  "亥",
  "イ",
  "ロ",
  "ハ",
  "ニ",
  "ホ",
  "ヘ",
  "ト",
  "チ",
  "リ",
  "ヌ",
  "ル",
  "ヲ",
  "ｲ",
  "ﾛ",
  "ﾊ",
  "ﾆ",
  "ﾎ",
  "ﾍ",
  "ﾄ",
  "ﾁ",
  "ﾘ",
  "ﾇ",
  "ﾙ",
  "ｦ",
];

/**
 * 種別の英語を日本語に変換する。
 * @param {string} value
 * @returns {string}
 */
const convertTypeEnglishToJapanese = (value: string): string => {
  if (value === "LAND") {
    return "土地";
  } else if (value === "BUILDING") {
    return "建物";
  } else if (value === "CONDOMINIUM") {
    return "区分建物";
  } else if (value === "ONE_BUILDING") {
    return "一棟";
  } else {
    return "";
  }
};

/**
 * 推計地積の値をフォーマット(少数第2位を切り捨て)
 * @param area 地積の値。nullの場合はデフォルト値として "—" を返す。
 * @return フォーマットされた推計地積の値。nullの場合は "—"。
 **/
const formatArea = (area: number | null): string => {
  return area ? numberFormat(area, { maximumFractionDigits: 2 }, "floor") : "—";
};

const CHIBAN_FULL_PATTERN = CHIBAN_PATTERN.join("|");

/**
 * 地番APIで取得した地番の中に不適切な文字列（道や無など）が含まれる場合はtrueを返す。
 * @param chiban 地番APIで取得した地番。
 * @return 不適切な文字列が含まれる場合はtrue、含まれない場合はfalse。
 **/
const skipSelectChiban = (chiban: string): boolean => {
  // 日本語の文字またはアルファベットを含む場合はスキップ（CHIBAN_FULL_PATTERNは除く）
  return (
    /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF a-zA-Z]/.test(chiban) &&
    !new RegExp(CHIBAN_FULL_PATTERN).test(chiban)
  );
};

const convertFullWidthDigitsAndHyphenToHalfWidth = (str: string): string => {
  return str.replace(/[０-９－]/g, (char) => {
    return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
  });
};

export {
  convertTypeEnglishToJapanese,
  formatArea,
  skipSelectChiban,
  convertFullWidthDigitsAndHyphenToHalfWidth,
};
