import { HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { useApiClient } from "@/hooks/useApiClient";
import type {
  ChibanRow,
  CreateKaokuNumberSearchParam,
  CreateKaokuNumberSearchRequestResponse,
  KaokuNumberRow,
  KaokuNumberSearchChiban,
  KaokuNumberSearchKaokuNumber,
  KaokuNumberSearchRequest,
} from "@/types/kaokuNumber";
import { type PropertySelectionRow } from "@/features/map/types";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { type BookTypeEng } from "@/types/acquirebook";
import { useState } from "react";

interface KaokuNumberApi {
  convertResultToKaokuNumbers: (
    response: KaokuNumberSearchRequest
  ) => KaokuNumberSearchKaokuNumber[];
  convertToChibanRows: () => ChibanRow[];
  convertToKaokuNumberRows: (
    rows: KaokuNumberSearchKaokuNumber[]
  ) => KaokuNumberRow[];
  convertToPropertySelectionRows: () => PropertySelectionRow[];
  createKaokuNumberSearchRequest: (
    params: CreateKaokuNumberSearchParam[]
  ) => Promise<CreateKaokuNumberSearchRequestResponse | undefined>;
  getKaokuNumberMaxRequestCount: () => number;
  getKaokuNumberSearchRequest: (
    id: number
  ) => Promise<KaokuNumberSearchRequest | undefined>;
  kaokuNumberRows: KaokuNumberRow[];
  setKaokuNumberRows: React.Dispatch<React.SetStateAction<KaokuNumberRow[]>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedChibanRowIds: GridSelectionModel;
  setSelectedChibanRowIds: React.Dispatch<
    React.SetStateAction<GridSelectionModel>
  >;
  selectedKaokuNumberRowIds: GridSelectionModel;
  setSelectedKaokuNumberRowIds: React.Dispatch<
    React.SetStateAction<GridSelectionModel>
  >;
  openSearchModalFlag: boolean;
  setOpenSearchModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  openAddRowsModalFlag: boolean;
  setOpenAddRowsModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  pollingRequestId: number | null;
  setPollingRequestId: React.Dispatch<React.SetStateAction<number | null>>;
}

/**
 * 家屋番号検索で使用するHooks及びState群
 * @param {KaokuNumberSearchChiban[]} chibanList
 * @returns {KaokuNumberApi}
 */
export const useKaokuNumber = (
  chibanList: KaokuNumberSearchChiban[]
): KaokuNumberApi => {
  const { apiClient } = useApiClient();

  /**
   * 家屋番号検索を依頼するAPIを実行する。
   * @param {CreateKaokuNumberSearchParam[]} requests
   * @returns {Promise<CreateKaokuNumberSearchRequestResponse | undefined>}
   */
  const createKaokuNumberSearchRequest = async (
    requests: KaokuNumberSearchChiban[]
  ): Promise<CreateKaokuNumberSearchRequestResponse | undefined> => {
    try {
      const response =
        await apiClient.post<CreateKaokuNumberSearchRequestResponse>(
          "/kaoku-number/",
          { requests }
        );

      return { ...response.data, status: response.status };
    } catch (error) {
      if (isAxiosError<CreateKaokuNumberSearchRequestResponse>(error)) {
        // Unauthorized以外のエラーが発生した場合、ステータスコードを返す
        if (error.response?.status !== HttpStatusCode.Unauthorized) {
          Sentry.captureException(error);

          return {
            status:
              error.response?.status ?? HttpStatusCode.InternalServerError, // ステータスコードが取得できない場合は500を返す
            error: error.response?.data?.error,
          };
        }
      }

      // その他のエラーの場合はundefinedを返す
      return undefined;
    }
  };

  /**
   * 家屋番号検索リクエストの詳細を取得するAPIを実行する。
   * @param {number} id
   * @returns {Promise<KaokuNumberSearchRequest | undefined>}
   */
  const getKaokuNumberSearchRequest = async (
    id: number
  ): Promise<KaokuNumberSearchRequest | undefined> => {
    const response = await apiClient
      .get<KaokuNumberSearchRequest>(`/kaoku-number/${id}`)
      .catch((error): void => {
        if (
          isAxiosError(error) &&
          error.response?.status !== HttpStatusCode.Unauthorized
        )
          Sentry.captureException(error);

        return undefined;
      });

    if (response) {
      return response.status === HttpStatusCode.Ok ? response.data : undefined;
    }
  };

  /**
   * 環境変数からVITE_KAOKU_NUMBER_MAX_REQUEST_COUNTを読み込んで数値型で返す。
   * 非数の場合はエラーを投げる。
   * @returns {number}
   */
  const getKaokuNumberMaxRequestCount = (): number => {
    const maxRequestCount = Number(
      process.env.VITE_KAOKU_NUMBER_MAX_REQUEST_COUNT
    );
    if (isNaN(maxRequestCount))
      throw new Error(
        "環境変数にVITE_KAOKU_NUMBER_MAX_REQUEST_COUNTが設定されていません。"
      );

    return maxRequestCount;
  };

  /**
   * KaokuNumberSearchChiban[]をChibanRow[]に変換する。
   * @returns {ChibanRow[]}
   */
  const convertToChibanRows = (): ChibanRow[] => {
    return chibanList.map((row, index) => {
      return { id: index, selected: true, ...row };
    });
  };

  /**
   * KaokuNumberSearchKaokuNumber[]をKaokuNumberRow[]に変換する。
   * @param {KaokuNumberSearchChiban[]} rows
   * @returns {ChibanRow[]}
   */
  const convertToKaokuNumberRows = (
    rows: KaokuNumberSearchKaokuNumber[]
  ): KaokuNumberRow[] => {
    return rows.map((row, index) => {
      return { id: index, selected: true, ...row };
    });
  };

  /**
   * KaokuNumberSearchRequestをKaokuNumberSearchKaokuNumber[]に変換する。
   * @param {KaokuNumberSearchRequest} response
   * @returns {KaokuNumberSearchKaokuNumber[]}
   */
  const convertResultToKaokuNumbers = (
    response: KaokuNumberSearchRequest
  ): KaokuNumberSearchKaokuNumber[] => {
    const kaokuNumberList: KaokuNumberSearchKaokuNumber[] = [];

    if (response.responses) {
      response.responses.results.forEach((result) => {
        result.kaokuNumberList.forEach((kaokuNumber) => {
          kaokuNumberList.push({
            prefectureName: result.pref,
            location: result.shozai,
            chiban: result.chiban,
            kaokuNumber,
            chibanFull: `${result.pref}${result.shozai}${result.chiban}`,
          });
        });
      });
    }

    return kaokuNumberList;
  };

  /**
   * KaokuNumberRow[]をPropertySelectionRow[]に変換する。
   * @returns {PropertySelectionRow[]}
   */
  const convertToPropertySelectionRows = (): PropertySelectionRow[] => {
    return kaokuNumberRows
      .filter((row) => {
        return selectedKaokuNumberRowIds.includes(row.id);
      })
      .map((row, index) => {
        return {
          id: index,
          bookType: "BUILDING" as BookTypeEng,
          selected: true,
          prefName: row.prefectureName,
          location: row.location,
          chibanKaokuNumber: row.kaokuNumber,
          area: null,
          isFeedOrigin: false,
        };
      });
  };

  // ローディングのState
  const [loading, setLoading] = useState<boolean>(false);
  // 家屋番号領域に表示するリストのState
  const [kaokuNumberRows, setKaokuNumberRows] = useState<KaokuNumberRow[]>([]);
  // 家屋番号領域で選択された行を保持するState
  const [selectedKaokuNumberRowIds, setSelectedKaokuNumberRowIds] =
    useState<GridSelectionModel>([]);
  // 地番領域で選択された行を保持するState
  const [selectedChibanRowIds, setSelectedChibanRowIds] =
    useState<GridSelectionModel>(() =>
      convertToChibanRows()
        .filter((row) => row.selected)
        .map((row) => row.id)
    );
  // 地番領域のボタン押下時確認ダイアログ表示State
  const [openSearchModalFlag, setOpenSearchModalFlag] =
    useState<boolean>(false);
  // 家屋番号領域のボタン押下時確認ダイアログ表示State
  const [openAddRowsModalFlag, setOpenAddRowsModalFlag] =
    useState<boolean>(false);
  // ポーリングしているリクエストIDのState
  const [pollingRequestId, setPollingRequestId] = useState<number | null>(null);

  return {
    convertResultToKaokuNumbers,
    convertToChibanRows,
    convertToKaokuNumberRows,
    convertToPropertySelectionRows,
    createKaokuNumberSearchRequest,
    getKaokuNumberMaxRequestCount,
    getKaokuNumberSearchRequest,
    kaokuNumberRows,
    setKaokuNumberRows,
    loading,
    setLoading,
    selectedChibanRowIds,
    setSelectedChibanRowIds,
    selectedKaokuNumberRowIds,
    setSelectedKaokuNumberRowIds,
    openSearchModalFlag,
    setOpenSearchModalFlag,
    openAddRowsModalFlag,
    setOpenAddRowsModalFlag,
    pollingRequestId,
    setPollingRequestId,
  };
};
