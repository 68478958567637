import { Box } from "@mui/material";
import { DatePicker } from "./DatePicker";

interface IProps {
  startDateLabel: string;
  startDateDisableFuture?: boolean;
  startDateDisablePast?: boolean;
  startDate: Date | null;
  onChangeStartDate: (date: Date | null) => void;
  endDateLabel: string;
  endDateDisableFuture?: boolean;
  endDateDisablePast?: boolean;
  endDate: Date | null;
  onChangeEndDate: (date: Date | null) => void;
  views?: Array<"year" | "month" | "day">;
  minDate?: Date;
  maxDate?: Date;
  defaultCalendarMonth?: Date;
  inputFormat?: string;
  disabled?: boolean;
}

const DateRangePicker: React.FC<IProps> = (props) => {
  const {
    startDateLabel,
    startDateDisableFuture = false,
    startDateDisablePast = false,
    startDate,
    onChangeStartDate,
    endDateLabel,
    endDateDisableFuture = false,
    endDateDisablePast = false,
    endDate,
    onChangeEndDate,
    views,
    minDate,
    maxDate,
    defaultCalendarMonth,
    inputFormat,
    disabled,
  } = props;

  // TODO: UIがダサいのでいい感じに

  return (
    <>
      <Box sx={{ mr: 1 }}>
        <DatePicker
          label={startDateLabel}
          value={startDate}
          views={views}
          onChange={onChangeStartDate}
          minDate={minDate}
          /** 開始日の最大値は、終了日または設定された値 */
          maxDate={endDate !== null ? endDate : maxDate}
          disableFuture={startDateDisableFuture}
          disablePast={startDateDisablePast}
          defaultCalendarMonth={defaultCalendarMonth}
          inputFormat={inputFormat}
          disabled={disabled}
        />
      </Box>

      <DatePicker
        label={endDateLabel}
        value={endDate}
        views={views}
        onChange={onChangeEndDate}
        /** 終了日の最小値は、開始日または設定された値 */
        minDate={startDate !== null ? startDate : minDate}
        maxDate={maxDate}
        disableFuture={endDateDisableFuture}
        disablePast={endDateDisablePast}
        defaultCalendarMonth={defaultCalendarMonth}
        inputFormat={inputFormat}
        disabled={disabled}
      />
    </>
  );
};

export { DateRangePicker, type IProps as DateRangePickerIProps };
