import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

interface ITextLinkProps {
  onClickHandler?: () => void;
  children: React.ReactNode;
}

export const TextLink: React.FC<ITextLinkProps> = ({
  onClickHandler,
  children,
}) => {
  return (
    <Box p={2}>
      <Stack spacing={2} direction="row-reverse">
        <span onClick={onClickHandler}>
          <Typography fontWeight={500} color="#0000FF" component={"div"}>
            {children}
          </Typography>
        </span>
      </Stack>
    </Box>
  );
};
