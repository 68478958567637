import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import { useFeatureFlags } from "@/configs/featureFlag";
import { AllMattersList } from "@/features/allMatters/routes/AllMattersList";
import { AllMattersDetail } from "@/features/allMatters/routes/AllMattersDetail";
import { AllMattersPDFUpload } from "@/features/allMatters/routes/AllMattersPDFUpload";

export const AllMattersRoutes: React.FC = () => {
  const { allMattersAnalysis } = useFeatureFlags();

  return (
    <Routes>
      <Route path="" element={<Navigate to="list" />} />
      {allMattersAnalysis ? (
        <Route path="list" element={<AllMattersList />} />
      ) : null}
      <Route path="detail/:id" element={<AllMattersDetail />} />
      {allMattersAnalysis ? (
        <Route path="upload" element={<AllMattersPDFUpload />} />
      ) : null}
      <Route path="*" element={<Navigate to="list" />} />
    </Routes>
  );
};
