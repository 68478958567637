import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { FeedGrid } from "../components/AllMattersDetailFeedGrid";
import { KeyboardArrowLeft } from "@mui/icons-material";
import React from "react";
import { useApiClient } from "@/hooks/useApiClient";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { isActionEnabled, statusToLabel } from "@/features/allMatters/utils";
import { type RequestDetailAndRealEstates } from "@/features/allMatters/types";
import CircularProgress from "@mui/material/CircularProgress";
import { useFeatureFlags } from "@/configs/featureFlag";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

export const AllMattersDetail: React.FC = () => {
  const { apiClient } = useApiClient();
  const { id } = useParams();
  const { mypageTop } = useFeatureFlags();
  const fetcher = async (url: string): Promise<RequestDetailAndRealEstates> => {
    const { data } = await apiClient.get<RequestDetailAndRealEstates>(url);
    return data;
  };

  const { data } = useSWR<RequestDetailAndRealEstates>(
    id ? `/owner-analyze/${id}` : null,
    fetcher,
    { revalidateOnFocus: true }
  );
  if (data == null) {
    return <CircularProgress />;
  }

  return (
    <PagePaper>
      <form>
        <Grid container sx={{ mb: 2 }} rowSpacing={1}>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item xs={12}>
                {/* ページタイトル */}
                <Box sx={{ display: "flex", mb: 3 }}>
                  <LibraryBooksIcon sx={{ mr: 1 }} fontSize="large" />
                  <PageTitle>全部事項解析詳細</PageTitle>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <Stack direction={"row"} spacing={2}>
                  <Typography color={"blue"}>
                    ステータス: {statusToLabel(data.status)}
                  </Typography>
                  <Typography color={"blue"}>
                    TOTAL:{" "}
                    <Typography component={"span"} color={"red"}>
                      {data?.realEstates.length}
                    </Typography>
                    件
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography>識別番号: {data?.taskId} </Typography>
                <Typography>識別名: {data?.identificationName}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <SectionPaper>
          <FeedGrid
            row={data?.realEstates}
            requestId={data?.id}
            disabledAction={!isActionEnabled(data?.status)}
          />
          <Box sx={{ mb: 6 }} />
          <Stack
            direction={"row"}
            spacing={4}
            justifyContent={"center"}
            mb={2}
          ></Stack>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
    </PagePaper>
  );
};
