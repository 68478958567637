import React from "react";

interface ValidateItem<T> {
  state: ValidateState;
  setState: React.Dispatch<React.SetStateAction<ValidateState>>;
  validate: (data: T) => boolean;
}
interface ValidateState {
  helperTextWhenError: string;
  error: boolean;
}

export interface Validator {
  pref: ValidateItem<string>;
  city: ValidateItem<string>;
  realEstateType: ValidateItem<string>;
  locationWithoutCity: ValidateItem<string>;
  chibanKaokuNumber: ValidateItem<string>;
  checkOptions: ValidateItem<Record<string, Record<string, boolean>>>;
}

const useValidator = (): Validator => {
  const [statePrefecture, setStatePrefecture] = React.useState<ValidateState>({
    error: false,
    helperTextWhenError: "",
  });
  const [stateCity, setStateCity] = React.useState<ValidateState>({
    error: false,
    helperTextWhenError: "",
  });
  const [stateRealEstateType, setStateRealEstateType] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });
  const [stateLocationWithoutCity, setStateLocationWithoutCity] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });
  const [stateChibanKaokuNumber, setStateChibanKaokuNumber] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });
  const [stateCheckOptions, setStateCheckOptions] =
    React.useState<ValidateState>({ error: false, helperTextWhenError: "" });

  const pref: ValidateItem<string> = {
    state: statePrefecture,
    setState: setStatePrefecture,
    validate: (data: string): boolean => {
      if (data !== "") {
        setStatePrefecture({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStatePrefecture({
          error: true,
          helperTextWhenError: "都道府県を選択してください",
        });
        return false;
      }
    },
  };
  const city: ValidateItem<string> = {
    state: stateCity,
    setState: setStateCity,
    validate: (data: string): boolean => {
      if (data !== "") {
        setStateCity({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStateCity({
          error: true,
          helperTextWhenError: "市区町村を選択してください",
        });
        return false;
      }
    },
  };
  const realEstateType: ValidateItem<string> = {
    state: stateRealEstateType,
    setState: setStateRealEstateType,
    validate: (data: string): boolean => {
      if (data !== "") {
        setStateRealEstateType({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStateRealEstateType({
          error: true,
          helperTextWhenError: "不動産種別を選択してください",
        });
        return false;
      }
    },
  };
  const locationWithoutCity: ValidateItem<string> = {
    state: stateLocationWithoutCity,
    setState: setStateLocationWithoutCity,
    validate: (data: string): boolean => {
      if (data !== "") {
        setStateLocationWithoutCity({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStateLocationWithoutCity({
          error: true,
          helperTextWhenError: "大字・町丁目を入力してください",
        });
        return false;
      }
    },
  };
  const chibanKaokuNumber: ValidateItem<string> = {
    state: stateChibanKaokuNumber,
    setState: setStateChibanKaokuNumber,
    validate: (data: string): boolean => {
      if (data !== "") {
        setStateChibanKaokuNumber({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStateChibanKaokuNumber({
          error: true,
          helperTextWhenError: "地番または家屋番号をを入力してください",
        });
        return false;
      }
    },
  };
  const checkOptions: ValidateItem<Record<string, Record<string, boolean>>> = {
    state: stateCheckOptions,
    setState: setStateCheckOptions,
    validate: (data: Record<string, Record<string, boolean>>): boolean => {
      // dataの中に一つでもチェックが入っているものがあればtrueとする
      const result = Object.keys(data).some((key) => data[key].checked);
      if (result) {
        // 現在事項がチェックされている場合、信託目録か共同担保目録のどちらかがチェックされている必要がある
        if (
          data.genzai.checked &&
          !(data.sintaku.checked || data.kyodo.checked)
        ) {
          setStateCheckOptions({
            error: true,
            helperTextWhenError:
              "現在事項を取得する場合には信託目録か共同担保目録を選択してください",
          });
          return false;
        }
        setStateCheckOptions({ error: false, helperTextWhenError: "" });
        return true;
      } else {
        setStateCheckOptions({
          error: true,
          helperTextWhenError: "取得したい登記/図面を選択してください",
        });
        return false;
      }
    },
  };

  return {
    pref,
    city,
    realEstateType,
    locationWithoutCity,
    chibanKaokuNumber,
    checkOptions,
  };
};

export { useValidator };
