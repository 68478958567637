import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Button, ButtonVariantOption, SubmitButton } from "../Button";

interface Props {
  chibanCount: number;
  handleClickSubmitSearchButton: (cancelLoading: () => void) => void;
  loading: boolean;
  openSearchModalFlag: boolean;
  setOpenSearchModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * 地番領域のボタン押下時に表示されるダイアログ
 * @param {Props} props
 * @returns {React.FC<Props>}
 */
export const ConfirmDialogChiban: React.FC<Props> = (props) => {
  const {
    chibanCount,
    handleClickSubmitSearchButton,
    loading,
    openSearchModalFlag,
    setOpenSearchModalFlag,
  } = props;

  return (
    <Dialog
      open={openSearchModalFlag}
      onClose={(_, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return;
        }
        setOpenSearchModalFlag(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"確認画面"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {chibanCount}件の地番を検索します。
        </DialogContentText>
        <DialogContentText>
          件数が多い場合、検索完了まで時間がかかります。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SubmitButton
          variant={ButtonVariantOption.Contained}
          onClick={(_event, cancelLoading) => {
            handleClickSubmitSearchButton(cancelLoading);
          }}
          label={"検索する"}
          timeout={30000}
        />
        <Button
          variant={ButtonVariantOption.Outlined}
          onClick={() => {
            setOpenSearchModalFlag(false);
          }}
          label={"キャンセル"}
          disabled={loading}
        />
      </DialogActions>
    </Dialog>
  );
};
