import { CustomModal } from "@/components/Modal";
import { PageTitle } from "@/components/Title";
import { AcquireBookFormUI } from "@/components/AcquireBook/AcquireBookFormUI";
import { type ISingleBookAcquireDialogData } from "@/features/realEstateReceptionBookFeed/types";
import Stack from "@mui/material/Stack";

interface IProps {
  acquireBookOpen: boolean;
  handleClose: () => void;
  row: ISingleBookAcquireDialogData;
}

export const AcquireBookModal: React.FC<IProps> = (props: IProps) => {
  const { acquireBookOpen, handleClose, row } = props;
  return (
    <CustomModal
      isOpen={acquireBookOpen}
      handleClose={() => {
        handleClose();
      }}
      ariaLabelledby="acquire-books-modal"
      ariaDescribedby="acquire-books-modal-description"
    >
      <Stack direction={"column"} spacing={3}>
        {/* タイトル */}
        <PageTitle>登記及び図面取得</PageTitle>

        {/* 選択したモニタリング詳細の情報表示部分 */}
        <AcquireBookFormUI mode={"modal"} singleBookAcquireDialogData={row} />
      </Stack>
    </CustomModal>
  );
};
