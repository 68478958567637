import { Button, ButtonVariantOption, SubmitButton } from "@/components/Button";
import { CDateRangePicker } from "@/components/DatePicker/CDateRangePicker";
import { CustomModal } from "@/components/Modal";
import { CMultiSelect } from "@/components/Select/CMultiSelect";
import { CTextFieldIdentifyName } from "@/components/TextField/CTextFieldIdentifyName";
import { PageTitle } from "@/components/Title";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { useReceptionReasonOptions } from "@/features/realEstateReceptionBookFeed/hooks/useReceptionReasonOptions";
import {
  getSearchMaxDate,
  getSearchMinDate,
  getStartAndEndDate,
} from "@/features/realEstateReceptionBookFeed/utils/dateTime";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { PostMonitoringAPI } from "@/features/monitoring/api/PostMonitoringAPI";
import { useApiClient } from "@/hooks/useApiClient";
import { useNavigate } from "react-router-dom";
import { type AxiosResponse, HttpStatusCode, isAxiosError } from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { CCheckbox } from "@/components/Checkbox/CCheckbox";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  realEstateOwnerAnalyzeRequestId?: number;
  pictureRequestId?: number;
}

/**
 * 実行するボタン押下時の表示されるモーダル。
 * @param {IProps} props
 * @returns
 */
export const ExecuteModal: React.FC<IProps> = (props: IProps) => {
  const { open, setOpen, realEstateOwnerAnalyzeRequestId, pictureRequestId } =
    props;

  // 課金確認モーダル開閉制御State
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  // 確認ボタン押下後のキャンセルボタン無効化State
  const [isPushedConfirmButton, setIsPushedConfirmButton] =
    useState<boolean>(false);

  // 登記要因セレクト項目
  const { receptionReasonOptions } = useReceptionReasonOptions({ short: true });

  // 法務局受付日 開始、終了の初期値
  const [startDate, endDate] = getStartAndEndDate();
  const minDate = getSearchMinDate();
  const maxDate = getSearchMaxDate();

  // React-Hook-Form用スキーマ定義
  const PostDataSchema = z.object({
    identifyName: z
      .string()
      .trim()
      .min(1, { message: "識別名を入力してください" })
      .max(256, { message: "256文字まで入力可能です" }),
    legalAffairsBureauRequestDateRange: z.tuple([
      z.date().min(minDate).max(maxDate),
      z.date().min(minDate).max(maxDate),
    ]),
    notMonthlyUpdatable: z.boolean(),
    receptionReasons: z
      .array(z.string())
      .min(1, "1つ以上登記原因を選択してください"),
  });

  // 定義したZodのスキーマをTypescriptの型に変換
  type PostRequestData = z.infer<typeof PostDataSchema>;

  const reactHookForm = useForm<PostRequestData>({
    mode: "all",
    defaultValues: {
      identifyName: "",
      legalAffairsBureauRequestDateRange: [startDate, endDate],
      notMonthlyUpdatable: false,
      receptionReasons: [],
    },
    resolver: zodResolver(PostDataSchema),
  });

  const showDatePicker = reactHookForm.watch("notMonthlyUpdatable");

  const { apiClient } = useApiClient();
  const navigator = useNavigate();

  // モニタリングするを押下した時の処理
  const handlePushedConfirmButton = (): void => {
    setIsConfirmModalOpen(true);
  };

  // 確認モーダルではいを選択した時の処理
  const handleExecuteSubmit = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cancelLoading: () => void
  ): void => {
    // NOTE: 月初日を取得するために、月を1進めて1日目を指定する
    const legalAffairsBureauRequestDateStart = new Date();
    legalAffairsBureauRequestDateStart.setFullYear(
      reactHookForm
        .getValues("legalAffairsBureauRequestDateRange")[0]
        .getFullYear(),
      reactHookForm
        .getValues("legalAffairsBureauRequestDateRange")[0]
        .getMonth(),
      1
    );

    // NOTE: 月末日を取得するために、月を1進めて0日目を指定する
    const legalAffairsBureauRequestDateEnd = new Date();
    legalAffairsBureauRequestDateEnd.setFullYear(
      reactHookForm
        .getValues("legalAffairsBureauRequestDateRange")[1]
        .getFullYear(),
      reactHookForm
        .getValues("legalAffairsBureauRequestDateRange")[1]
        .getMonth() + 1,
      0
    );

    setIsPushedConfirmButton(true);
    PostMonitoringAPI(apiClient, {
      name: reactHookForm.getValues("identifyName"),
      receptionReasonIds: reactHookForm.getValues("receptionReasons"),
      realEstateOwnerAnalyzeRequestId,
      pictureRequestId,
      monthlyUpdatable: !reactHookForm.getValues("notMonthlyUpdatable"),
      legalAffairsBureauRequestDateStart,
      legalAffairsBureauRequestDateEnd,
    })
      .then((response) => {
        navigator(`/monitoring/${response.id}/`);
      })
      .catch((error) => {
        if (
          isAxiosError<AxiosResponse>(error) &&
          (error.response?.status === HttpStatusCode.BadRequest ||
            error.response?.status === HttpStatusCode.NotFound)
        ) {
          toast.error("モニタリング登録ができませんでした。");
          return;
        }
        if (
          isAxiosError<AxiosResponse>(error) &&
          (error.response?.status ?? 500) >= HttpStatusCode.InternalServerError
        ) {
          toast.error("モニタリング登録に失敗しました。再度お試しください。");
        }
      })
      .finally(() => {
        reactHookForm.reset();
        cancelLoading();
        setIsPushedConfirmButton(false);
        setIsConfirmModalOpen(false);
        setOpen(false);
      });
  };

  return (
    <>
      <CustomModal
        isOpen={open}
        handleClose={(
          event: Record<string, unknown>,
          reason: "backdropClick" | "escapeKeyDown"
        ): void => {
          if (
            reason &&
            (reason === "backdropClick" || reason === "escapeKeyDown")
          ) {
            return;
          }
          setOpen(false);
        }}
        ariaLabelledby="execute-modal"
        ariaDescribedby="execute-modal-description"
        maxHeight={"90vh"}
        overflow="scroll"
      >
        <Stack direction={"column"} spacing={3}>
          {/* タイトル */}
          <PageTitle>モニタリング登録内容 設定・確認</PageTitle>

          {/* メッセージ */}
          <Typography>モニタリング定義を設定してください。</Typography>

          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={5.5}>
                  <Typography py={1.5}>識別名</Typography>
                  <CTextFieldIdentifyName
                    name={"identifyName"}
                    control={reactHookForm.control}
                    size={SizingWrapperStyle.LARGE}
                  />
                </Grid>
                <Grid item xs={6.5}>
                  <Typography py={1.5}>
                    モニタリング対象にする登記原因
                  </Typography>
                  <CMultiSelect<PostRequestData>
                    name={"receptionReasons"}
                    control={reactHookForm.control}
                    label="登記原因"
                    options={receptionReasonOptions}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  rowGap: 2,
                  py: 2,
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <CCheckbox<PostRequestData>
                  name={"notMonthlyUpdatable"}
                  control={reactHookForm.control}
                  label={"月次でモニタリングしない"}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  rowGap: 2,
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <CDateRangePicker<PostRequestData>
                  name={"legalAffairsBureauRequestDateRange"}
                  control={reactHookForm.control}
                  startDateLabel="法務局受付日 開始"
                  endDateLabel="法務局受付日  終了"
                  minDate={minDate}
                  maxDate={maxDate}
                  defaultCalendarMonth={startDate}
                  disabled={!showDatePicker}
                  views={["year", "month"]}
                  inputFormat={"yyyy/MM"}
                />
              </Box>
            </Grid>
          </Grid>

          {/* ボタン */}
          <Stack
            direction={"row"}
            spacing={2}
            marginTop={2}
            justifyContent={"center"}
          >
            <Button
              label="モニタリングする"
              onClick={handlePushedConfirmButton}
              disabled={!reactHookForm.formState.isValid}
              size={SizingWrapperStyle.SMALL}
              variant={ButtonVariantOption.Contained}
            />
            <Button
              label="キャンセル"
              onClick={() => {
                reactHookForm.reset();
                setOpen(false);
              }}
              size={SizingWrapperStyle.SMALL}
              variant={ButtonVariantOption.Outlined}
            />
          </Stack>
        </Stack>
      </CustomModal>
      {/* 課金確認モーダル */}
      <CustomModal
        isOpen={isConfirmModalOpen}
        handleClose={(
          event: Record<string, unknown>,
          reason: "backdropClick" | "escapeKeyDown"
        ): void => {
          if (
            reason &&
            (reason === "backdropClick" || reason === "escapeKeyDown")
          ) {
            return;
          }
          setIsConfirmModalOpen(false);
        }}
        ariaLabelledby="confirm-modal"
        ariaDescribedby="confirm-modal-description"
      >
        <PageTitle>確認</PageTitle>
        <Box>
          <Typography>課金が発生します。よろしいですか？</Typography>

          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <SubmitButton
              label={"はい"}
              timeout={120_000} // ms
              onClick={handleExecuteSubmit}
              variant={ButtonVariantOption.Contained}
            />
            <Button
              label="いいえ"
              disabled={isPushedConfirmButton}
              onClick={() => {
                setIsConfirmModalOpen(false);
              }}
              variant={ButtonVariantOption.Outlined}
            />
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};
