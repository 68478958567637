import {
  type AxiosInstance,
  type AxiosResponse,
  HttpStatusCode,
  isAxiosError,
} from "axios";
import * as Sentry from "@sentry/react";
import {
  type PutMonitoringAPIRequest,
  type PutMonitoringAPIResponse,
} from "@/features/monitoring/types";

/**
 * モニタリング登録を更新するAPIを実行する。
 * @param {AxiosInstance} apiClient
 * @param {PutMonitoringAPIRequest} requests
 */
export const PutMonitoringAPI = async (
  apiClient: AxiosInstance,
  requests: PutMonitoringAPIRequest
): Promise<PutMonitoringAPIResponse> => {
  const url = "/monitoring/";

  try {
    const response = await apiClient.put<PutMonitoringAPIResponse>(
      url,
      requests
    );

    return response.data;
  } catch (error) {
    if (
      isAxiosError<AxiosResponse>(error) &&
      (error.response?.status ?? 500) >= HttpStatusCode.InternalServerError
    ) {
      Sentry.captureException(error);
    }
    throw error;
  }
};
