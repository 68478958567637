import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Button, ButtonVariantOption } from "../Button";
import { StyledDataGridChiban } from "./StyledDataGridChiban";
import { type GridSelectionModel } from "@mui/x-data-grid";
import { type ChibanRow } from "@/types/kaokuNumber";
import { toast } from "react-toastify";

interface Props {
  chibanCount: number;
  handleRowSelectionChange: (newSelectionModel: GridSelectionModel) => void;
  loading: boolean;
  maxRequestCount: number;
  rows: ChibanRow[];
  selectedRowIds: GridSelectionModel;
  setOpenSearchModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * 地番の表示領域
 * @param {Props} props
 * @returns {React.FC<Props>}
 */
export const ViewAreaChiban: React.FC<Props> = (props) => {
  const {
    chibanCount,
    handleRowSelectionChange,
    loading,
    maxRequestCount,
    rows,
    selectedRowIds,
    setOpenSearchModalFlag,
  } = props;

  return (
    <>
      <Typography sx={{ pl: 2, pt: 1, fontWeight: "bold" }}>地番</Typography>
      <Box marginY={1} marginX={2} sx={{ height: 200 }}>
        <StyledDataGridChiban
          handleRowSelectionChange={handleRowSelectionChange}
          rows={rows}
          selectedRowIds={selectedRowIds}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="end"
        spacing={1}
        marginTop={1}
        marginBottom={2}
        marginRight={2}
      >
        <Button
          label="家屋番号検索"
          variant={ButtonVariantOption.Contained}
          onClick={() => {
            if (chibanCount > maxRequestCount) {
              toast.error(`件数は${maxRequestCount}件以下にしてください。`);
              return;
            }
            setOpenSearchModalFlag(true);
          }}
          disabled={loading}
        />
      </Stack>
    </>
  );
};
