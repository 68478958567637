import React, { useId } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Button, ButtonVariantOption } from "@/components/Button";
import { type DialogProps } from "@mui/material/Dialog/Dialog";

export interface RealEstateDetailDialogContent {
  title: React.ReactNode;
  value: React.ReactNode;
}

interface IRealEstateDetailModalProps {
  title: React.ReactNode;
  contents?: RealEstateDetailDialogContent[];
}

/**
 * 不動産情報の詳細を表示するモーダル
 * contents配列の内容を全て展開して配置します
 * @param props
 */
const RealEstateDetailModal: React.FC<
  IRealEstateDetailModalProps & DialogProps
> = (props: IRealEstateDetailModalProps & DialogProps) => {
  const { open, onClose, fullWidth, maxWidth, title, contents } = props;

  const [openState, setOpenState] = React.useState(open);

  const ariaLabel = useId();
  const ariaDescribe = useId();

  return (
    <Dialog
      open={openState}
      onClose={(event, reason) => {
        setOpenState(false);
        if (onClose) {
          onClose(event, reason);
        }
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaDescribe}
    >
      <DialogTitle id={ariaLabel}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id={ariaDescribe}
          sx={{ display: "flex", flexWrap: "wrap" }}
          component="dl"
        >
          {contents?.map((item, index) => (
            <React.Fragment key={index}>
              <Typography
                component={"dt"}
                fontWeight={"bold"}
                width={"25%"}
                my={1}
              >
                {item.title}
              </Typography>
              <Typography component={"dd"} sx={{ ml: 2 }} width={"70%"} my={1}>
                {item.value}
              </Typography>
            </React.Fragment>
          ))}
        </DialogContentText>
      </DialogContent>
      <Box sx={{ mx: "auto", my: 4 }}>
        <Button
          variant={ButtonVariantOption.Contained}
          onClick={() => {
            setOpenState(false);
          }}
          disabled={false}
          label={"閉じる"}
        />
      </Box>
    </Dialog>
  );
};
export default RealEstateDetailModal;
