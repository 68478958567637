import { Route, Routes } from "react-router-dom";
import React from "react";
import { MonitoringDetail } from "./MonitoringDetail";
import { MonitoringDetailReceptionBook } from "./MonitoringDetailReceptionBook";
import { Monitoring } from "@/features/monitoring/routes/Monitoring";
import { MonitoringUpload } from "@/features/monitoring/routes/MonitoringUpload";

export const MonitoringRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="detail/:id" element={<MonitoringDetailReceptionBook />} />
      <Route path=":id" element={<MonitoringDetail />} />
      <Route path="upload" element={<MonitoringUpload />} />
      <Route path="" element={<Monitoring />} />
    </Routes>
  );
};
