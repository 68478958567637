import { Box, Modal } from "@mui/material";

// モーダルコンポーネントを一般化して、再利用可能にします。
interface ModalProps {
  isOpen: boolean;
  handleClose: (
    event: Record<string, unknown>,
    reason: "backdropClick" | "escapeKeyDown"
  ) => void;
  children: React.ReactNode;
  ariaLabelledby: string;
  ariaDescribedby: string;
  height?: number | string;
  width?: number | string;
  maxHeight?: number | string;
  overflow?: "scroll" | "hidden" | "auto";
}

export const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  children,
  ariaLabelledby,
  ariaDescribedby,
  height,
  maxHeight,
  width = 800,
  overflow,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={ariaLabelledby}
      aria-describedby={ariaDescribedby}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height,
          maxHeight,
          width,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow,
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};
